import { useNavigate } from "react-router";
import { Add } from "@mui/icons-material";
import { AxiosError } from "axios";
import { Button, IconButton, Tooltip } from "@mui/material";

import { repoTypes } from "types";

import { ExtList } from "components/organisms";
import { RepoTeaser } from "components/molecules";

const RepoLists = (
  groupData: any & {
    new: boolean;
    isLoading: boolean;
    refresh: () => void;
    error: AxiosError<any>;
  }
) => {
  const navigate = useNavigate();
  const newUrl = `/groups/${groupData.id}/new-repo`;

  return (
    <>
      <ExtList
        title="Repositories"
        listActions={
          <Tooltip title="New repository">
            <IconButton onClick={() => navigate(newUrl)}>
              <Add />
            </IconButton>
          </Tooltip>
        }
        paginated
        pageSize={10}
        searchEnabled
        items={groupData?.repositories}
        loading={groupData.isLoading}
        refresh={groupData.refresh}
        error={groupData?.error?.response?.data?.error}
        createEntry={(repo: repoTypes.Repository) => (
          <RepoTeaser {...repo} groupId={groupData.id} key={repo.id} />
        )}
        addItemAction={
          <Button
            variant="text"
            onClick={() => navigate(newUrl)}
            className="w-full flex items-center"
          >
            <Add />
            Add a new repository
          </Button>
        }
      />
    </>
  );
};

export default RepoLists;
