import { Button } from "@mui/material";
import { ComponentProps } from "react";

const ButtonTertiary = (props: ComponentProps<typeof Button> & {active?: boolean}) => {
  return (
    <div className={(props.active ? "border-b-4 border-brand -mb-1" : "") + " " + props.className}>
      <Button {...props}></Button>
    </div>
  );
};

export default ButtonTertiary;
