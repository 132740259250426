import { SubmitHandler } from "react-hook-form";

import { environmentsService, repositoriesService } from "services";
import { envTypes } from "types";

import OptionsEnvForm from "./OptionsEnvForm";

const CreateEnvForm = (props: {
  env: envTypes.Environment;
  onClose: () => void;
}) => {
  const {
    mutate: create,
    isLoading: createLoading,
    error,
  } = environmentsService.useCreate(props.env.id);

  const { data: repo } = repositoriesService.useRepo(props.env.rid);

  const handleFormSubmit: SubmitHandler<envTypes.CreateEnvProps> = (data) => {
    const ttlNumber = Number(data.ttl);
    var options: envTypes.CreateEnvProps = {
      build: data.build,
      name: data.name || undefined,
      ttl: !isNaN(ttlNumber) ? ttlNumber : undefined,
      notReuse: data.notReuse ? true : undefined,
      reusableLabel: data.reusableLabel || undefined,
    };

    create(options, {
      onSuccess: () => {
        setTimeout(props.onClose, 500);
      },
    });
  };

  return (
    <OptionsEnvForm
      title={"Configure environment"}
      subtitle={props.env.gitRef.name}
      buttonText={"Create"}
      loadingText={"Creating..."}
      error={error?.message}
      loading={createLoading}
      onSubmit={handleFormSubmit}
      reusables={repo?.reusableEnvironments}
    />
  );
};

export default CreateEnvForm;
