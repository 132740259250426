import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ButtonTertiary, Loader, Subtitle } from "components/atoms";
import { useContext, useState } from "react";
import environmentsService from "services/environments";
import { NotificationsState } from "store";
import { envTypes } from "types";

const QuickActionButton = (props: {
  action: envTypes.QuickAction;
  envId: string;
}) => {
  const { mutate: execQuickAction, isLoading: loading } =
    environmentsService.useExecQuickAction(props.envId, props.action.name);

  const { addNotification } = useContext(NotificationsState);
  const [result, setResult] = useState<string>("");

  const handleRun = () => {
    if (props.action.output === "file") {
      window.open(
        `/api/environment/${props.envId}/quickaction/${props.action.name}`
      );
      addNotification({
        type: "success",
        message: "Action completed, downloading file...",
      });
    } else {
      execQuickAction({} as any, {
        onSuccess: (response) => {
          if (response) {
            addNotification({
              type: "success",
              message: "Action completed",
            });
            setResult(response);
          } else {
            addNotification({
              type: "error",
              message: "Action failed",
            });
          }
        },
      });
    }
  };

  const handleCopyResult = () => {
    navigator.clipboard.writeText(result);
    addNotification({
      type: "success",
      message: "Copied to clipboard",
    });
  };

  const detectURL = (text: string) => {
    if (text.startsWith("http://") || text.startsWith("https://")) {
      return (
        <a href={text} target="_blank" rel="noreferrer" className="underline">
          {text}
        </a>
      );
    } else {
      return text;
    }
  };

  return (
    <>
      <Dialog open={result !== ""} onClose={() => {}}>
        <DialogTitle>
          <Subtitle>Result of {props.action.name}</Subtitle>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <code className="break-all">
              {result.split(" ").map((text) => detectURL(text))}
            </code>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonTertiary onClick={handleCopyResult}>Copy result</ButtonTertiary>
          <ButtonTertiary
            className="mr-auto"
            startIcon={loading && <Loader />}
            onClick={handleRun}
          >
            Run again
          </ButtonTertiary>
          <Button onClick={() => setResult("")}>Close</Button>
        </DialogActions>
      </Dialog>
      <ButtonTertiary startIcon={loading && <Loader size="small" />} onClick={handleRun}>
        {props.action.name}
      </ButtonTertiary>
    </>
  );
};

const EnvQuickActions = (env: envTypes.Environment) => {
  if (env.status !== "built") {
    return [];
  }
  return (
    env.quickActions?.map((action, index) => (
      <>
        {index === 0 && <div className="w-px border-r" />}
        <QuickActionButton action={action} envId={env.id} />
      </>
    )) ?? []
  );
};

export default EnvQuickActions;
