import { useCallback, useContext, useMemo } from "react";
import { userTypes } from "types";
import { useLocalStorage } from "./localStorage";
import { NotificationsState } from "store";

const useFavorites = (
  content: Partial<userTypes.User["recentlyVisited"][0]>
) => {
  const [favorites, setFavorites] = useLocalStorage("favorites", []);
  const { addNotification } = useContext(NotificationsState);

  const lastAdded = useMemo(() => favorites[0], [favorites]);

  const isFavorite = favorites.some(
    (item: any) => item.visitedId === content.visitedId
  );

  const toggleFavorite = useCallback(
    () => {
      if (!content.visitedId) return;
      const newFavorites = favorites.filter(
        (item: userTypes.User["recentlyVisited"][0]) =>
          item.visitedId !== content.visitedId
      );
      // if already in favorites, remove it
      if (!isFavorite) {
        newFavorites.unshift(content);
      }
      // if more than 6 items, remove the last one
      if (newFavorites.length < 10) {
        console.log("update favorites");
        setFavorites(newFavorites);}
      else addNotification({
        type: "error",
        message: "You can only have 10 favorites",
      });
    },
    [content, favorites, isFavorite, setFavorites, addNotification]
  );

  return { favorites, toggleFavorite, lastAdded, isFavorite };
}

export { useFavorites };