import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Settings } from "@mui/icons-material";

import { repoTypes } from "types";
import { RepositoryIcon } from "components/atoms";
import { RepoInfo } from "components/molecules";
import Teaser from "./baseTeaser";

const RepoTeaser = (repo: repoTypes.Repository & { groupId: string }) => {
  return (
    <Teaser
      fullLink={"/groups/" + repo.groupId + "/repos/" + repo.id + "/envs"}
      icon={<RepositoryIcon type={repo.type} size="medium" />}
      title={repo.name}
      type={repo.type}
      info={<RepoInfo {...repo} mode="teaser" />}
      actions={
        <>
          <Link
            to={"/groups/" + repo.groupId + "/repos/" + repo.id + "/settings"}
          >
            <IconButton>
              <Settings />
            </IconButton>
          </Link>
        </>
      }
    />
  );
};

export { RepoTeaser };
