import {
  Alert,
  Divider,
  FormLabel,
  InputAdornment,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { envTypes } from "types";

import { ButtonPrimary, Loader } from "components/atoms";
import React from "react";

const OptionsEnvForm = (props: {
  onSubmit: SubmitHandler<any>;
  title: string;
  subtitle: string;
  buttonText: string;
  loading: boolean;
  loadingText: string;
  error?: string;
  reusables?: envTypes.ReusableEnvironment[];
}) => {
  const { control, handleSubmit } = useForm<
    envTypes.CloneEnvProps | envTypes.CreateEnvProps
  >();

  const [notReuse, setNotReuse] = React.useState(false);

  return (
    <form
      className="flex flex-col p-m gap-s min-w-[350px]"
      onSubmit={handleSubmit(props.onSubmit)}
    >
      <p className="text-l font-medium pb-s">{props.title}</p>

      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            label="Name"
            type="text"
            size="small"
            defaultValue={props.subtitle}
            {...field}
            onChange={(e) => field.onChange(e.target.value)}
          />
        )}
      />
      <Controller
        name="ttl"
        control={control}
        render={({ field }) => (
          <TextField
            label="TTL"
            type="number"
            size="small"
            placeholder="Leave empty for default"
            inputProps={{ min: 0 }}
            {...field}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">minutes</InputAdornment>
                </>
              ),
            }}
            onChange={(e) => field.onChange(e.target.value.replace(" ", "-"))}
          />
        )}
      />
      <Alert severity="info" className="max-w-[300px]">
        This is the time in minutes after which the environment will be
        automatically deleted. Set to 0 for no deletion.
      </Alert>
      <Divider />
      <Controller
        name="build"
        control={control}
        defaultValue={true}
        render={({ field }) => (
          <div className="pl-s flex gap-s justify-between items-center">
            <FormLabel>Build</FormLabel>
            <Switch
              size="small"
              defaultChecked
              {...field}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          </div>
        )}
      />
      <Divider />

      <Controller
        name="notReuse"
        control={control}
        defaultValue={props.reusables?.length ? false : true}
        render={({ field }) => (
          <div className="pl-s flex gap-s justify-between items-center">
            <FormLabel>Reuse</FormLabel>
            <Switch
              size="small"
              defaultChecked={!field.value}
              onChange={(e) => {
                setNotReuse(!e.target.checked);
                field.onChange(!e.target.checked);
              }}
            />
          </div>
        )}
      />
      <Divider />

      <Controller
        name="reusableLabel"
        control={control}
        disabled={notReuse}
        defaultValue={""}
        render={({ field }) => (
          <div className="pl-s flex gap-s justify-between items-center">
            <FormLabel>Reuse from</FormLabel>
            <Select
              size="small"
              native
              {...field}
              onChange={(e) => field.onChange(e.target.value)}
            >
              {props.reusables?.length && <option value="">-not specified-</option>}
              {props.reusables?.map((reusable) => (
                <option key={reusable.id} value={reusable.label}>
                  {reusable.label}
                </option>
              ))}
              {!props.reusables?.length && (
                <option value="" disabled>
                  none available
                </option>
              )}
            </Select>
          </div>
        )}
      />

      <Divider />
      <ButtonPrimary type="submit">
        {props.loading ? (
          <>
            {props.loadingText} &nbsp;
            <Loader />
          </>
        ) : (
          <p>{props.buttonText}</p>
        )}
      </ButtonPrimary>
      {props.error && <p>{props.error}</p>}
    </form>
  );
};

export default OptionsEnvForm;
