import { QueryClient, QueryClientProvider } from "react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

import { UserContext, NotificationsContext } from "store";
import themeOptions from "style/theme.json";
import { routes } from "pages/routes";
import { queryConfig } from "services/config";

import "./index.css";
import { NotificationSnacks } from "components/organisms";

function Qaack() {
  const router = createBrowserRouter(routes);
  const theme = createTheme(themeOptions);
  const queryClient = new QueryClient(queryConfig);

  return (
    <div className="App" id={"App"}>
      <QueryClientProvider client={queryClient}>
        <UserContext>
          <NotificationsContext>
            <ThemeProvider theme={theme}>
              <NotificationSnacks />
              <RouterProvider router={router} />
            </ThemeProvider>
          </NotificationsContext>
        </UserContext>
      </QueryClientProvider>
    </div>
  );
}

export default Qaack;
