import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import {
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Equalizer, Logout, Menu } from "@mui/icons-material";

import { UserState } from "store";

import { NavigationSelectors, NotificationMenu } from "components/organisms";
import icon from "assets/svg/icon-green.svg";

const Sidebar = (props: { mobile?: boolean; openMobile?: () => void }) => {
  const navigate = useNavigate();
  const uContext = useContext(UserState);

  const handleLogOut = () => {
    Cookies.remove("token");
    uContext.refetch();
    navigate("/");
  };

  const handleOpenMobile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    props.openMobile && props.openMobile();
  };

  const responsiveClasses = props.mobile ? "pb-s h-full" : "w-full md:min-w-[18rem] md:w-[18rem] md:pb-s md:h-full";
  const responsiveSectionClasses = props.mobile ? "flex" : "hidden md:flex";

  return (
    <aside
      className={
        "flex border-r border-neutral/20 flex-col px-s bg-white " +
        responsiveClasses
      }
    >
      {/* Sidebar HEADER */}
      <section
        className="flex w-full justify-between gap-m items-center cursor-pointer col-span-3 h-xl mb-s"
        onClick={() => {
          navigate("/");
        }}
      >
        <IconButton
          onClick={handleOpenMobile}
          className={props.mobile ? "!hidden" : "md:!hidden"}
        >
          <Menu />
        </IconButton>
        <span className="flex gap-s items-center font-mono tracking-wider text-[18px] bg-clip-text text-transparent bg-gradient-to-r to-brand-light from-brand">
          <img src={icon} className="h-[45px]" alt="icon" />
          <b className="hidden sm:block">QAACK</b>
        </span>
      </section>

      {/* Sidebar content */}
      <section
        className={"flex flex-col gap-m " + responsiveSectionClasses}
      >
        <NavigationSelectors />
      </section>

      {/* Sidebar FOOTER / PROFILE / Logout */}
      <section
        className={"flex flex-col mt-auto pb-0 " + responsiveSectionClasses}
      >
        <div className="flex justify-between items-center gap-s">
          <Tooltip title="Profile">
            <Link to="/profile" className="mt-auto grow">
              <IconButton id="profile-button">
                <Avatar className="!bg-brand/50 " >
                  {uContext.userInfo.username?.charAt(0) ??
                    uContext.userInfo.email?.charAt(0)}
                </Avatar>
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Dashboard">
            <Link to="/dashboard">
              <IconButton>
                <Equalizer />
              </IconButton>
            </Link>
          </Tooltip>
          <NotificationMenu />
          <Tooltip title="Logout">
            <IconButton onClick={handleLogOut}>
              <Logout className="!text-status-error" />
            </IconButton>
          </Tooltip>
        </div>
      </section>
    </aside>
  );
};

export default Sidebar;
