import { Equalizer } from "@mui/icons-material";

import { metricsService } from "services";

import { Title } from "components/atoms";
import { CircularProgressWithLabel } from "components/molecules";

import NormalPage from "./layout/page";

export default function Dashboard() {
  const { data: runtimeMetrics } = metricsService.useRuntimeMetrics();

  const { data: usersMetrics } = metricsService.useUsersMetrics();
  const { data: reposMetrics } = metricsService.useReposMetrics();
  const { data: envsMetrics } = metricsService.useEnvsMetrics();
  const { data: servicesMetrics } = metricsService.useServicesMetrics();

  return (
    <NormalPage
      title={
        <div>
          <div className="flex gap-s items-center ">
            <Equalizer className="!fill-brand" fontSize="large" />
            <Title>Dashboard Metrics</Title>
          </div>
        </div>
      }
    >
      <div className="mb-l grid gap-m justify-between sm:grid-cols-2 lg:grid-cols-4">
        {[
          { label: "Users", metrics: usersMetrics },
          { label: "Repos", metrics: reposMetrics },
          { label: "Envs", metrics: envsMetrics },
          { label: "Services", metrics: servicesMetrics },
        ].map(
          ({ label, metrics }) =>
            metrics && (
              <div className="flex flex-col gap-s flex-wrap bg-white p-s rounded py-m shadow-sm">
                <CircularProgressWithLabel
                  values={Object.entries(metrics).map(([key, value]) => ({
                    value,
                    label: key,
                  }))}
                  total={metrics.total}
                  label={label}
                  key={label}
                />
                <ul className="ml-m pl-xs mr-s">
                  {Object.entries(metrics)
                    .filter(([key]) => !key.includes("total"))
                    .sort((a, b) => b[1] - a[1])
                    .map(([key, value], i) => (
                      <li key={key} className="relative flex w-full">
                        {Array.from(Array(i + 1).keys()).map((i) => (
                          <span
                            key={i}
                            className="absolute bg-brand opacity-40 left-0 -ml-s h-s w-s top-s"
                          />
                        ))}
                        <span className="ml-s mr-s font-bold text-neutral text-m">
                          {key}:
                        </span>{" "}
                        <span className="ml-auto font-regular text-neutral text-m pr-s">
                          {value}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            )
        )}
      </div>

      {runtimeMetrics && (
          <div className="p-m grid gap-l sm:grid-cols-2 lg:grid-cols-4 bg-white rounded shadow-sm">
            {Object.entries(runtimeMetrics).map(([key, value]) => (
              <CircularProgressWithLabel
                values={[
                  {
                    value,
                    label: key.replace("Value", "").replace("usage", "Usage "),
                  },
                ]}
                label={key.replace("Value", "").replace("usage", "Usage ")}
                key={key}
                unit={key.includes("Value") ? "GB" : "%"}
              />
            ))}
          </div>
      )}
    </NormalPage>
  );
}
