import { useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { To, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { NotificationsState } from "store";
import { repoTypes } from "types";
import { groupsService, repositoriesService } from "services";
import { RepositoryIcon, Subtitle, Title } from "components/atoms";
import { CodeEditor } from "components/molecules";

const AVAILABLE_REPO_TYPES = [
  "azure",
  "bitbucket",
  "gitlab",
  "github",
  "generic",
] as repoTypes.RepoType[];

const RepoParams = (props: { type: repoTypes.RepoType; control: any }) => {
  switch (props.type) {
    case "azure":
      return (
        <>
          <Controller
            name="params.orgUrl"
            control={props.control}
            render={({ field }) => (
              <TextField
                size="small"
                className="bg-white"
                label="Organization URL"
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">The org of your Azure repo</p>
            <p className="text-s text-neutral">
              You can find it in the url as: https://dev.azure.com/
              <strong>qaackOrg</strong>/...
            </p>
          </div>

          <Controller
            name="params.project"
            control={props.control}
            render={({ field }) => (
              <TextField
                size="small"
                className="bg-white"
                label="Project Name"
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              The project of your Azure repo
            </p>
            <p className="text-s text-neutral">
              You can find it in the url as: https://dev.azure.com/qaackOrg/
              <strong>qaack</strong>/...
            </p>
          </div>

          <Controller
            name="params.repoName"
            control={props.control}
            render={({ field }) => (
              <TextField
                size="small"
                className="bg-white"
                label="Repository Name"
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">The repository name</p>
            <p className="text-s text-neutral">
              You can find it in the url as: https://dev.azure.com/qaackOrg/
              qaack/_git/<strong>qaack</strong>
            </p>
          </div>

          <Controller
            name="params.accessToken"
            control={props.control}
            render={({ field }) => (
              <TextField
                size="small"
                className="bg-white"
                label="Access token"
                {...field}
              />
            )}
          />
          <p className="text-s text-neutral">
            You can use{" "}
            <Link href="https://learn.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate?view=azure-devops&tabs=Windows">
              personal access tokens
            </Link>
            {/* TODO specify permissions needed */}
          </p>
        </>
      );
    case "generic":
      return (
        <>
          <Controller
            name="params.origin"
            control={props.control}
            render={({ field }) => (
              <TextField
                {...field}
                className="bg-white"
                label="Origin"
                size="small"
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              You can use https basic auth:{" "}
              <strong>https://user:pass/token@host</strong>
            </p>
            <p className="text-s text-neutral">
              or SSH auth: <strong>git@host:user/repo</strong>
            </p>
          </div>
        </>
      );
    case "gitlab":
      return (
        <>
          <Controller
            name="params.instanceUrl"
            control={props.control}
            render={({ field }) => (
              <TextField
                className="bg-white"
                label="Instance URL"
                size="small"
                placeholder={"https://gitlab.com"}
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              The URL of the GitLab instance where the repository is hosted.
            </p>
            <p className="text-s text-neutral">
              Leave blank for https://gitlab.com
            </p>
          </div>

          <Controller
            name="params.group"
            control={props.control}
            render={({ field }) => (
              <TextField
                size="small"
                label="Group"
                {...field}
                className="bg-white"
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">The group of your GitLab repo</p>
            <p className="text-s text-neutral">
              You can find it in the url as: https://gitlab.com/
              <strong>qaack</strong>/qaack
            </p>
          </div>

          <Controller
            name="params.project"
            control={props.control}
            render={({ field }) => (
              <TextField
                size="small"
                label="Project"
                {...field}
                className="bg-white"
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              The project of your GitLab repo
            </p>
            <p className="text-s text-neutral">
              You can find it in the url as: https://gitlab.com/qaack/
              <strong>qaack</strong>
            </p>
          </div>

          <Controller
            name="params.token"
            control={props.control}
            render={({ field }) => (
              <TextField
                label="Access Token"
                size="small"
                {...field}
                className="bg-white"
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              You can use{" "}
              <Link href="https://docs.gitlab.com/ee/user/project/settings/project_access_tokens.html">
                project tokens
              </Link>{" "}
              or{" "}
              <Link href="https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html">
                personal tokens
              </Link>
            </p>
            <p className="text-s text-neutral">
              the access token needs <strong>maintainer</strong> role with{" "}
              <strong>api</strong> and <strong>read_repository</strong> scopes.
            </p>
          </div>
        </>
      );
    case "github":
      return (
        <>
          <Controller
            name="params.owner"
            control={props.control}
            render={({ field }) => (
              <TextField
                label="Owner"
                size="small"
                className="bg-white"
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              The owner of your GitHub repo, could be a user or an organization.
            </p>
            <p className="text-s text-neutral">
              You can find it in the url as: https://github.com/
              <strong>qaack</strong>/...
            </p>
          </div>
          <Controller
            name="params.repo"
            control={props.control}
            render={({ field }) => (
              <TextField
                label="Repository"
                size="small"
                className="bg-white"
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              The repository name of your GitHub repo.
            </p>
            <p className="text-s text-neutral">
              You can find it in the url as: https://github.com/qaack/
              <strong>qaack</strong>
            </p>
          </div>
          <Controller
            name="params.token"
            control={props.control}
            render={({ field }) => (
              <TextField
                label="Access Token"
                size="small"
                className="bg-white"
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              You can use{" "}
              <Link href="https://github.com/settings/personal-access-tokens/new">
                personal access tokens
              </Link>
            </p>
            <p className="text-s text-neutral">
              The access token needs <strong>repo</strong> and{" "} 
              <strong>Content (read-only)</strong>, {" "}
              <strong>Pull Requests (read and write)</strong> and {" "}
              <strong>Webhooks (read and write)</strong> {" "}
              scopes
            </p>
          </div>
        </>
      );
    case "bitbucket":
      return (
        <>
          <Controller
            name="params.workspace"
            control={props.control}
            render={({ field }) => (
              <TextField
                label="Workspace"
                size="small"
                className="bg-white"
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              The workspace of your Bitbucket repo.
            </p>
            <p className="text-s text-neutral">
              You can find it in the url as: https://bitbucket.org/
              <strong>qaackWorkspace</strong>/...
            </p>
          </div>

          <Controller
            name="params.repository"
            control={props.control}
            render={({ field }) => (
              <TextField
                label="Repository"
                size="small"
                className="bg-white"
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              The repository name of your Bitbucket repo.
            </p>
            <p className="text-s text-neutral">
              You can find it in the url as: https://bitbucket.org/
              qaackWorkspace/<strong>qaack</strong>
            </p>
          </div>

          <Controller
            name="params.token"
            control={props.control}
            render={({ field }) => (
              <TextField
                label="Access Token"
                size="small"
                className="bg-white"
                {...field}
              />
            )}
          />
          <div>
            <p className="text-s text-neutral">
              You can use{" "}
              <Link href="https://support.atlassian.com/bitbucket-cloud/docs/access-tokens/">
                access tokens
              </Link>{" "}
              of different types.
            </p>
            {/* TODO specify permissions needed */}
          </div>
        </>
      );

    default:
      return null;
  }
};

const NewRepoForm = (props: { groupId: string; redirectUrl: To | number }) => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<repoTypes.NewRepoProps>();

  const { addNotification } = useContext(NotificationsState);

  const {
    mutate: createRepo,
    isLoading,
    isError,
    error,
  } = repositoriesService.useCreateRepo(props.groupId);

  const { refetch: refreshGroup } = groupsService.useGroup(props.groupId);

  const handleFormSubmit: SubmitHandler<repoTypes.NewRepoProps> = (data) =>
    createRepo(data, {
      onSuccess: () => {
        addNotification({
          message: "Repository created successfully",
          type: "success",
        });
        refreshGroup();
        typeof props.redirectUrl === "number"
          ? navigate(props.redirectUrl)
          : navigate(props.redirectUrl);
      },
    });

  const [selectedQaackFileMode, setSelectedQaackFileMode] =
    useState<repoTypes.QaackFileMode>("repo");
  const availableModes = ["panel", "repo", "both"] as repoTypes.QaackFileMode[];

  const [selectedRepoType, setSelectedRepoType] =
    useState<repoTypes.RepoType>();

  return (
    <form
      className="flex flex-col md:grid md:grid-cols-[33%_auto] gap-m 2xl:w-3/4"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Title>New repository</Title>
      <br className="hidden md:block" />

      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            className="bg-white col-span-1"
            size="small"
            label="Name"
            {...field}
          />
        )}
      />
      <p className="text-s text-neutral">It can be changed later</p>

      <Subtitle>Platform config</Subtitle>
      <br className="hidden md:block" />

      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <FormControl size="small">
            <InputLabel id="platform-select-label">Platform</InputLabel>
            <Select
              labelId="platform-select-label"
              label="Platform"
              className="bg-white"
              {...field}
              onChange={(e: SelectChangeEvent) => {
                setSelectedRepoType(e.target.value as repoTypes.RepoType);
                field.onChange(e);
              }}
              renderValue={(value) => (
                <div className="flex items-center gap-s">
                  <RepositoryIcon
                    type={value as repoTypes.RepoType}
                    size="small"
                  />
                  <p>{value}</p>
                </div>
              )}
            >
              {AVAILABLE_REPO_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  <RepositoryIcon type={type} size="small" />
                  <p className="mx-m">{type}</p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <div>
        <p className="text-s text-neutral">
          Choose the platform where your repository is hosted.
        </p>
        <p className="text-s text-neutral">
          We have special integrations and webhooks adapted to each platform.
        </p>
        <p className="text-s text-neutral">
          If your platform is not listed, you can use the generic option for
          now.
        </p>
      </div>

      {selectedRepoType && (
        <RepoParams type={selectedRepoType} control={control} />
      )}

      <Subtitle>Build config</Subtitle>
      <br className="hidden md:block" />
      <Controller
        name="qaackFile.mode"
        control={control}
        render={({ field }) => (
          <FormControl size="small">
            <InputLabel id="qaack-file-mode-select-label">
              Qaack File Mode
            </InputLabel>
            <Select
              labelId="qaack-file-mode-select-label"
              className="bg-white"
              size="small"
              label="Qaack File Mode"
              {...field}
              onChange={(e) => {
                setSelectedQaackFileMode(
                  e.target.value as repoTypes.QaackFileMode
                );
                field.onChange(e);
              }}
            >
              {availableModes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <div>
        <p className="text-s text-neutral">
          Qaack reads the environment build instructions from a config yml file.
        </p>
        <p className="text-s text-neutral">
          You can create a new Qaack File as code on your repository or add it
          here in the panel.
        </p>
        <p className="text-s text-neutral">
          If you choose "both", panel's config will be used if the repo doesn't
          have one.
        </p>
      </div>

      {selectedQaackFileMode !== "repo" && (
        <Controller
          name="qaackFile.qaackFile"
          control={control}
          render={({ field }) => (
            <div className="col-span-2 overflow-x-hiden pt-m">
              <CodeEditor
                className="p-xs"
                {...field}
                showQaackFileTemplates
                label="Qaack File"
              />
            </div>
          )}
        />
      )}

      <Button
        className="font-bold col-start-1"
        variant="contained"
        type="submit"
        disabled={!selectedRepoType || !selectedQaackFileMode}
      >
        Create
      </Button>

      {isLoading && <p>Loading...</p>}
      {isError && (
        <FormHelperText error>
          {(error as AxiosError<any>).response?.data?.error}
        </FormHelperText>
      )}
    </form>
  );
};

export default NewRepoForm;
