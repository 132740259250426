import { FaCodeBranch, FaCodePullRequest, FaTag } from "react-icons/fa6";
import { envTypes } from "types";
import { MdAdjust } from "react-icons/md";

const GitRefIcon = (props: {
  status: envTypes.Environment["status"];
  gitRef: envTypes.Environment["gitRef"];
  size: "small" | "medium" | "large" | "xl";
}) => {
  let colorClass = "text-neutral/80";
  // colors brand if built, neutral if empty and error if not built
  switch (props.status) {
    case "built":
      colorClass = "!text-brand";
      break;
    case "failed":
      colorClass = "!text-status-error";
      break;
  }

  const numSize =
    props.size === "xl"
      ? "1.7em"
      : props.size === "large"
      ? "1.5em"
      : props.size === "medium"
      ? "1.45em"
      : "1.2em";

  switch (props.gitRef.type) {
    case "branch":
      return <FaCodeBranch className={colorClass} size={numSize} />;
    case "tag":
      return <FaTag className={colorClass} size={numSize} />;
    case "pullrequest":
      return <FaCodePullRequest className={colorClass} size={numSize} />;
    case "mergerequest":
      return <FaCodePullRequest className={colorClass} size={numSize} />;
    default:
      return <MdAdjust className={colorClass} size={numSize} />;
  }
};

export default GitRefIcon;
