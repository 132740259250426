import { useContext, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { To, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { ContentPaste } from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  FormHelperText,
  FormLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";

import { userTypes } from "types";
import { NotificationsState, UserState } from "store";
import { userService } from "services";

import { FormTitle } from "components/atoms";

const NewApiTokenForm = (props: {
  userId: string;
  redirectUrl: To | number;
}) => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<userTypes.ApiToken>();
  const {
    mutate: createUserApiToken,
    isLoading: loadingUserApiToken,
    isError: isUserApiTokenError,
    error: userApiTokenError,
  } = userService.useCreateUserApiToken();

  const { addNotification } = useContext(NotificationsState);
  const { refetch } = useContext(UserState);

  const [newToken, setNewToken] = useState();

  const handleFormSubmit: SubmitHandler<userTypes.ApiToken> = (data) => {
    createUserApiToken(data, {
      onSuccess: (response) => {
        setNewToken(response.token);
      },
    });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(newToken ?? "");
    addNotification({
      type: "info",
      message: "Token copied to clipboard",
    });
  };

  const handleClose = () => {
    refetch();
    typeof props.redirectUrl === "number"
      ? navigate(props.redirectUrl)
      : navigate(props.redirectUrl);
  };

  return (
    <form
      className="flex flex-col gap-s p-m min-w-[350px]"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <FormTitle>New API token</FormTitle>

      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField label="Name" {...field} disabled={!!newToken} />
        )}
      />
      <Divider />
      <Controller
        name="expiresAt"
        control={control}
        render={({ field }) => (
          <>
            <FormLabel>Expiration date</FormLabel>
            <TextField
              type="date"
              {...field}
              onChange={(e) =>
                field.onChange(new Date(e.target.value).toISOString())
              }
              // we need to correctly display the date
              value={
                field.value
                  ? new Date(field.value).toISOString().split("T")[0]
                  : ""
              }
              disabled={!!newToken}
            />
          </>
        )}
      />
      <Divider />
      <Controller
        name="readOnly"
        control={control}
        render={({ field }) => (
          <div className="pl-s flex gap-s justify-between items-center">
            <FormLabel>Read only</FormLabel>
            <Switch
              {...field}
              onChange={(e) => field.onChange(e.target.checked)}
              disabled={!!newToken}
            />
          </div>
        )}
      />
      <Divider />

      {newToken ? (
        <div className="my-m flex flex-col gap-m">
          <div className="flex gap-s items-center justify-between border border-brand rounded-lg px-s py-xs text-brand">
            <p> {newToken}</p>
            <Tooltip
              title="Copy SSH key to clipboard"
              className="group-hover:opacity-100"
            >
              <IconButton onClick={handleCopyToClipboard}>
                <ContentPaste
                  onClick={handleCopyToClipboard}
                  className="cursor-pointer text-brand"
                />
              </IconButton>
            </Tooltip>
          </div>

          <Alert severity="info">
            Copy it, it won't be accessible anymore.
          </Alert>

          <Button
            className="font-bold mt-l"
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      ) : (
        <Button className="font-bold" variant="contained" type="submit">
          Create
        </Button>
      )}

      {loadingUserApiToken && <FormHelperText>Loading...</FormHelperText>}
      {isUserApiTokenError && (
        <FormHelperText error>
          {(userApiTokenError as AxiosError<any>)?.response?.data?.error}
        </FormHelperText>
      )}
    </form>
  );
};

export default NewApiTokenForm;
