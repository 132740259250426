import { useContext } from "react";
import { Avatar, Button, SwipeableDrawer } from "@mui/material";

import { userTypes } from "types";
import { UserState } from "store";

import { SaveOnBlurInput, Subtitle, Title } from "components/atoms";
import { ApiTokenTeaser, SettingOption } from "components/molecules";
import { ExtList } from "components/organisms";

import NormalPage from "./layout/page";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import NewApiTokenForm from "components/organisms/forms/NewApiTokenForm";
import { userService } from "services";

export default function Profile(props: { newToken?: boolean }) {
  const { userInfo, refetch } = useContext(UserState);
  const navigate = useNavigate();

  const {
    mutate: updateUsername,
    isLoading: loadingUpdateUsername,
    isError: isUpdateUsernameError,
  } = userService.useUpdateUser();
  const onUpdateUsername = (value: string) => {
    updateUsername(
      { username: value },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const redirectUrl = -1;
  const newUrl = "tokens/new";

  const NewApiTokenModal = () => (
    <SwipeableDrawer
      anchor="right"
      open={props.newToken ?? false}
      onOpen={() => navigate(newUrl)}
      onClose={() => navigate(redirectUrl)}
    >
      <NewApiTokenForm
        userId={userInfo?.id ?? "missing"}
        redirectUrl={redirectUrl}
      />
    </SwipeableDrawer>
  );

  return (
    <NormalPage
      title={
        <div className="flex gap-s items-center mb-s">
          <Avatar className="col-span-1 !bg-brand/50">
            {userInfo?.username?.charAt(0) ?? userInfo?.email?.charAt(0)}
          </Avatar>
          <Title>{userInfo?.username}</Title>
          <Subtitle>{userInfo?.email}</Subtitle>
        </div>
      }
    >
      <Subtitle>General</Subtitle>
      <SettingOption
        type="normal"
        title="Username"
        description="This name will be displayed in your profile and in your repositories. You can edit it."
        actions={
          <SaveOnBlurInput
            value={userInfo?.username}
            onBlur={(value) => onUpdateUsername(value.toString())}
            loading={loadingUpdateUsername}
            error={isUpdateUsernameError}
            name="name"
            type="text"
          />
        }
      />
      <SettingOption
        type="normal"
        title="Email"
        description="You can't change your email for now."
        actions={
          <SaveOnBlurInput
            value={userInfo?.email}
            loading={false}
            error={false}
            name="email"
            type="email"
            disabled
          />
        }
      />
      <SettingOption
        type="normal"
        title="Password"
        description="You can't change your password for now."
        actions={
          <>
            <SaveOnBlurInput
              value="********"
              loading={false}
              error={false}
              name="password"
              type="password"
              disabled
            />
          </>
        }
      />

      <ExtList
        title={"API Tokens"}
        items={(userInfo?.apiTokens ?? []) as userTypes.ApiToken[]}
        refresh={refetch}
        addItemAction={
          <Button
            variant="text"
            onClick={() => navigate(newUrl)}
            className="w-full flex items-center"
          >
            <Add />
            Add a new API token
          </Button>
        }
        createEntry={(apiToken) => (
          <ApiTokenTeaser key={apiToken.id} {...apiToken} />
        )}
      />

      <NewApiTokenModal />
    </NormalPage>
  );
}
