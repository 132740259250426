import { useQuery } from "react-query";
import { axiosInstance } from "./config";
import { metricsTypes } from "types";

const requests = {
  getRuntimeMetrics: () =>
    axiosInstance.get("/metrics/runtime").then((response) => response.data),
  getUserMetrics: () =>
    axiosInstance.get("/metrics/users").then((response) => response.data),
  getRepoMetrics: () =>
    axiosInstance.get("/metrics/repos").then((response) => response.data),
  getEnvMetrics: () =>
    axiosInstance.get("/metrics/envs").then((response) => response.data),
  getServicesMetrics: () =>
    axiosInstance.get("/metrics/services").then((response) => response.data),
  getEnvAvailabilityMetrics: () =>
    axiosInstance.get("/metrics/availability").then((response) => response.data),
  getBuildQueueMetrics: () =>
    axiosInstance.get("/metrics/buildqueue").then((response) => response.data),
};

const metricsService = {
  useRuntimeMetrics: () =>
    useQuery<metricsTypes.RuntimeMetrics, Error>(
      "getRuntimeMetrics",
      requests.getRuntimeMetrics,
      {
        refetchInterval: 5000,
      }
    ),
  useUsersMetrics: () =>
    useQuery<metricsTypes.Metrics, Error>(
      "getUsersMetrics",
      requests.getUserMetrics
    ),
  useReposMetrics: () =>
    useQuery<metricsTypes.Metrics, Error>(
      "getReposMetrics",
      requests.getRepoMetrics
    ),
  useEnvsMetrics: () =>
    useQuery<metricsTypes.Metrics, Error>(
      "getEnvsMetrics",
      requests.getEnvMetrics
    ),
  useServicesMetrics: () =>
    useQuery<metricsTypes.Metrics, Error>(
      "getServicesMetrics",
      requests.getServicesMetrics
    ),
  useEnvAvailabilityMetrics: () =>
    useQuery<metricsTypes.Metrics, Error>(
      "getEnvAvailabilityMetrics",
      requests.getEnvAvailabilityMetrics
    ),
  useBuildQueueMetrics: () =>
    useQuery<metricsTypes.Metrics, Error>(
      "getBuildQueueMetrics",
      requests.getBuildQueueMetrics
    ),
};

export default metricsService;
