import { Star } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const FavoriteButton = (props: {
  isFavorite: boolean;
  toggleFavorite: () => void;
}) => {
  const { isFavorite, toggleFavorite } = props;
  return (
    <IconButton onClick={toggleFavorite}>
      <Star color={isFavorite ? "primary" : "disabled"} />
    </IconButton>
  );
}

export default FavoriteButton;