import { testTypes } from "types";

import { TestTeaser } from "components/molecules";
import { ExtList } from "components/organisms";

const TestLists = (props: {
  tests: testTypes.Test[];
  isLoading: boolean;
}) => {

  if (!props.tests.length) {
    return null;
  }

  return (
    <ExtList
      title={"Tests"}
      items={props.tests}
      loading={props.isLoading}
      createEntry={(test) => <TestTeaser {...test} key={test.id} />}
      collapsible
      counter={props.tests.length}
    />
  );
};

export default TestLists;
