import { MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const SelectorInput = (props: {
  value?: string;
  onChange: (value: string) => void;
  options: string[];
  label: string;
  optionComponent?: React.FC;
}) => {
  const [localValue, setLocalValue] = useState<string | undefined>(props.value);

  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
    props.onChange?.(e.target.value);
  };

  return (
    <TextField
      className="w-full"
      size="small"
      select
      label={props.label}
      value={localValue}
      onChange={handleChange}
      key={props.label + localValue}
    >
      {props.options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectorInput;
