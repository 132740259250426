import { useMemo } from "react";
import prettyBytes from "pretty-bytes";

import { envTypes } from "types";
import { DurationString, getTimeDeltaString } from "utils";

const EnvInfo = (
  env: envTypes.Environment & { mode: "teaser" | "details" }
) => {
  const expireDate = useMemo(() => {
    if (!env?.createdAt || !env?.ttl || env.status === "empty") return null;
    const createdAt = new Date(env?.createdAt ?? "");
    return new Date(createdAt.getTime() + (env?.ttl ?? 0) * 60000);
  }, [env?.createdAt, env?.ttl, env?.status]);

  const textClasses = useMemo(() => {
    const baseClasses = "text-neutral";
    switch (env.mode) {
      case "teaser":
        return `${baseClasses} text-sm`;
      case "details":
        return `${baseClasses} text-m`;
    }
  }, [env.mode]);

  type InfoItem = { text: string; title?: string };
  const infoItems = [] as InfoItem[];

  if (env?.status === "building" && env?.buildStartedAt)
    infoItems.push({
      text: `for ${getTimeDeltaString(new Date(env?.buildStartedAt))}`,
      title: new Date(env?.buildStartedAt).toLocaleString(),
    });

  if (env?.buildTime)
    infoItems.push({
      text: `after ${DurationString(env?.buildTime / 1000)}`,
    });

  if (["built", "cancelled"].includes(env?.status) && env?.buildStartedAt)
    if (env?.buildTime) {
      // sum of build time and time since build started
      const buildFinishedAt = new Date(
        new Date(env?.buildStartedAt).getTime() + env?.buildTime
      );
      infoItems.push({
        text: ` ${getTimeDeltaString(buildFinishedAt)} ago`,
        title: "Completed at " + buildFinishedAt.toLocaleString(),
      });
    }
  if (env?.status === "failed" && env?.failedAt) {
    if (!env?.buildTime && env?.createdAt && env?.failedAt) {
      var duration =
        Math.abs(
          new Date(env?.failedAt).getTime() -
            new Date(env?.createdAt || "").getTime()
        ) / 1000;

      infoItems.push({
        text: `after ${DurationString(duration)}`,
      });
    }
    if (env?.failedAt)
      infoItems.push({
        text: `${getTimeDeltaString(new Date(env?.failedAt))} ago`,
        title: "Failed at " + new Date(env?.failedAt).toLocaleString(),
      });
  }

  if (expireDate)
    infoItems.push({
      text: `expires in ${getTimeDeltaString(expireDate)}`,
      title: "Expires on " + expireDate.toLocaleString(),
    });
  if (env?.size)
    infoItems.push({
      text: `occupies ${prettyBytes(env.size)}`,
    });
  if (env?.reusing)
    infoItems.push({
      text: `reusing ${env.reusing}`,
    });

  return (
    <>
      {infoItems.map((item, index) => (
        <p key={index} className={textClasses} title={item.title}>
          {item.text}
          {index < infoItems.length - 1 && ","}
        </p>
      ))}
    </>
  );
};

export default EnvInfo;
