import { useState } from "react";
import { IconButton, Menu, Tooltip } from "@mui/material";

import NotificationList from "./NotificationList";
import NotificationCounter from "./NotificationCounter";

const NotificationMenu = () => {
  const [anchorElNotifications, setAnchorElNotifications] =
    useState<null | HTMLElement>(null);

  const handleOpenNotificationsMenu = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotificationsMenu = () => {
    setAnchorElNotifications(null);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton onClick={handleOpenNotificationsMenu}>
          <NotificationCounter />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-notifications"
        anchorEl={anchorElNotifications}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElNotifications)}
        onClose={handleCloseNotificationsMenu}
      >
        <NotificationList />
      </Menu>
    </>
  );
};

export default NotificationMenu;