import { FaPause, FaPlay } from "react-icons/fa6";
import { envTypes } from "types";

const ServiceStatusIcon = (service: envTypes.Service) => {
  return service.Status?.Running ? (
      <FaPlay />
  ) : (
      <FaPause />
  );
};

export default ServiceStatusIcon;
