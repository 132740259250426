import { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { Group as GroupIcon, Inventory, Settings } from "@mui/icons-material";

import { groupsService } from "services";
import { useFavorites, useRecentlyVisited } from "store";

import NormalPage from "./layout/page";
import { ButtonTertiary, FavoriteButton, Title } from "components/atoms";
import { GroupSettings, NewRepoForm, RepoLists } from "components/organisms";

export default function Group(props: {
  new?: boolean;
  newVar?: boolean;
  newMember?: boolean;
}) {
  let params = useParams();
  const navigate = useNavigate();

  const [showSettings, showRepos, showNewRepo] = useMemo(
    () => [
      params.tab === "settings",
      params.tab === "repos",
      params.tab === "new-repo",
    ],
    [params.tab]
  );

  const handleTabSwitch = (tab: string) => {
    const currentPath = window.location.pathname;
    const newPath = params.tab
      ? currentPath.replace(params.tab, tab)
      : currentPath + "/" + tab;
    navigate(newPath);
  };

  const {
    data: groupData,
    isLoading,
    refetch: refresh,
    error,
  } = groupsService.useGroup(params.groupId);

  const entryListItem = useMemo(() => ({
    type: "group",
    data: groupData,
    visitedId: groupData?.id,
  } as const), [groupData]);
  useRecentlyVisited(entryListItem);
  const {toggleFavorite, isFavorite} = useFavorites(entryListItem);

  return (
    <NormalPage
      title={
        <div>
          <div className="flex gap-s items-center ">
            <GroupIcon className="!fill-brand" fontSize="large" />
            <Title>{groupData?.name}</Title>
          </div>
        </div>
      }
      tabs={[
        <ButtonTertiary
          onClick={() => handleTabSwitch("settings")}
          active={showSettings}
          startIcon={<Settings />}
        >
          Settings
        </ButtonTertiary>,
        <ButtonTertiary
          onClick={() => handleTabSwitch("repos")}
          active={showRepos || showNewRepo}
          startIcon={<Inventory />}
        >
          Repos
        </ButtonTertiary>,
        <FavoriteButton toggleFavorite={toggleFavorite} isFavorite={isFavorite} />
      ]}
    >
      {showRepos && (
        <RepoLists
          {...groupData}
          new={props.new}
          isLoading={isLoading}
          loading={isLoading}
          refresh={refresh}
          error={error}
        />
      )}
      {showSettings && !!groupData && (
        <GroupSettings
          group={groupData}
          varModalOpen={props.newVar ?? false}
          memberModalOpen={props.newMember ?? false}
        />
      )}
      {showNewRepo && (
        <NewRepoForm
          groupId={params.groupId ?? ""}
          redirectUrl={`/groups/${params.groupId}/repos`}
        />
      )}
    </NormalPage>
  );
}
