import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

import { axiosInstance } from "./config";
import { envTypes, repoTypes } from "types";
import { CloneEnvProps } from "types/environmentTypes";

const requests = {
  getRepos: (groupId?: string) =>
    axiosInstance.get("/group/" + groupId).then((response) => response.data),

  getRepo: (repoId?: string) =>
    axiosInstance
      .get("/repository/" + (repoId || "missing"))
      .then((response) => response.data),
  getRepoName: (repoId?: string) =>
    repoId
      ? axiosInstance
        .get("/repository/" + (repoId || "missing"))
        .then((response) => response.data.name)
      : undefined,

  createRepo: (groupId?: string, newRepo?: repoTypes.NewRepoProps) =>
    axiosInstance
      .post("/group/" + (groupId || "missing"), newRepo)
      .then((response) => response.data),
  updateRepo: (repoId?: string, newRepo?: Partial<repoTypes.NewRepoProps>) =>
    axiosInstance
      .post("/repository/" + (repoId || "missing"), newRepo)
      .then((response) => response.data),
  deleteRepo: (repoId?: string) =>
    axiosInstance
      .delete("/repository/" + (repoId || "missing"))
      .then((response) => response.data),

  getRepoSettings: (repoId?: string) =>
    axiosInstance
      .get("/repository/" + (repoId || "missing") + "/settings")
      .then((response) => response.data as repoTypes.RepositorySettings),

  updateRepoSettings: (
    repoId?: string,
    settings?: {
      newTTL?: number;
      newScheduledRebuild?: repoTypes.ScheduledRebuildSettings;
      newQaackFile?: repoTypes.QaackFileSettings;
      webhook?: repoTypes.WebhookTriggerSettings;
    }
  ) =>
    axiosInstance
      .post("/repository/" + (repoId || "missing") + "/settings", {
        ...(settings?.newTTL && { environmentTTL: settings?.newTTL }),
        ...(settings?.newScheduledRebuild && {
          scheduledRebuild: settings?.newScheduledRebuild,
        }),
        ...(settings?.newQaackFile && {
          qaackFile: settings?.newQaackFile,
        }),
        ...(settings?.webhook && {
          webhook: settings?.webhook,
        })
      })
      .then((response) => response.data),

  createVariable: (repoId?: string, newVariable?: repoTypes.Variable) =>
    axiosInstance
      .post("/repository/" + (repoId || "missing") + "/variables", newVariable)
      .then((response) => response.data),
  removeVariable: (repoId?: string, variableName?: string) =>
    axiosInstance
      .delete(
        "/repository/" + (repoId || "missing") + "/variables/" + variableName
      )
      .then((response) => response.data),

  sync: (repoId?: string) =>
    axiosInstance
      .post("/repository/" + (repoId || "missing") + "/sync")
      .then((response) => response.data),

  spawnEnv: (repoId?: string, options?: envTypes.CloneEnvProps) =>
    axiosInstance
      .post("/repository/" + (repoId || "missing") + "/spawn", options)
      .then((response) => response.data),

  removeReusable: (repoId?: string, reusableLabel?: string) =>
    axiosInstance
      .delete(
        "/repository/" +
        (repoId || "missing") +
        "/reusable/" +
        (reusableLabel || "missing")
      )
      .then((response) => response.data),
};

const repositoriesService = {
  useRepos: (groupId?: string) =>
    useQuery<repoTypes.Repository[], AxiosError>(
      "getRepos" + (groupId || "missing"),
      () => requests.getRepos(groupId),
      { enabled: !!groupId }

    ),

  useSync: (repoId?: string) =>
    useMutation<any, AxiosError>("sync", () => requests.sync(repoId)),

  useRepo: (repoId?: string) =>
    useQuery<repoTypes.Repository, AxiosError>(
      "getRepo" + (repoId || "missing"),
      () => requests.getRepo(repoId),
      { enabled: !!repoId }
    ),
  useRepoName: (repoId?: string) =>
    useQuery<any, AxiosError>("getRepoName" + (repoId || "missing"), () =>
      requests.getRepoName(repoId)
    ),

  useCreateRepo: (groupId?: string) =>
    useMutation<any, AxiosError, repoTypes.NewRepoProps>(
      "createRepo" + (groupId || "missing"),
      (newRepo?: repoTypes.NewRepoProps) =>
        requests.createRepo(groupId, newRepo)
    ),

  useUpdateRepo: (repoId?: string) =>
    useMutation<any, AxiosError, Partial<repoTypes.NewRepoProps>>(
      "updateRepo" + (repoId || "missing"),
      (newRepo?: Partial<repoTypes.NewRepoProps>) =>
        requests.updateRepo(repoId, newRepo)
    ),

  useDeleteRepo: (repoId?: string) =>
    useMutation<any, AxiosError>("deleteRepo" + (repoId || "missing"), () =>
      requests.deleteRepo(repoId)
    ),

  useRepoSettings: (repoId?: string) =>
    useQuery<repoTypes.RepositorySettings, AxiosError>(
      "getRepoSettings" + (repoId || "missing"),
      () => requests.getRepoSettings(repoId)
    ),

  useUpdateSettings: (repoId?: string) =>
    useMutation<
      any,
      AxiosError,
      {
        newTTL?: number;
        newScheduledRebuild?: repoTypes.ScheduledRebuildSettings;
        newQaackFile?: repoTypes.QaackFileSettings;
        webhook?: repoTypes.WebhookTriggerSettings;
      }
    >(
      "updateRepoSettings" + (repoId || "missing"),
      (settings?: {
        newTTL?: number;
        newScheduledRebuild?: repoTypes.ScheduledRebuildSettings;
        newQaackFile?: repoTypes.QaackFileSettings;
        webhook?: repoTypes.WebhookTriggerSettings;
      }) => requests.updateRepoSettings(repoId, settings)
    ),

  useCreateVariable: (repoId?: string) =>
    useMutation<any, AxiosError, repoTypes.Variable>(
      "createVariable" + (repoId || "missing"),
      (newVariable?: repoTypes.Variable) =>
        requests.createVariable(repoId, newVariable)
    ),
  useRemoveVariable: (repoId?: string) =>
    useMutation<any, AxiosError, string>(
      "removeVariable" + (repoId || "missing"),
      (variableName) => requests.removeVariable(repoId, variableName)
    ),

  useSpawnEnv: (repoId?: string) =>
    useMutation<any, AxiosError, CloneEnvProps>(
      "spawnEnv" + (repoId || "missing"),
      (options: CloneEnvProps) => requests.spawnEnv(repoId, options)
    ),

  useRemoveReusable: (repoId?: string, reusableLabel?: string) =>
    useMutation<any, AxiosError>(
      "removeReusable" + (repoId || "missing") + (reusableLabel || "missing"),
      () => requests.removeReusable(repoId, reusableLabel)
    ),
};

export default repositoriesService;
