import { useContext } from "react";
import { Notifications } from "@mui/icons-material";
import { Badge } from "@mui/material";

import { NotificationsState } from "store";

const NotificationCounter = () => {
  const { notifications } = useContext(NotificationsState);
  return (
    <Badge badgeContent={notifications.length}>
      <Notifications />
    </Badge>
  );
};

export default NotificationCounter;
