import { ReactNode } from "react";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";

const LinkWrapper = (props: { link?: string; children: ReactNode }) => {
  if (props.link) {
    return (
      <Link className="flex-grow" to={props.link}>
        {props.children}
      </Link>
    );
  }
  return <>{props.children}</>;
};

const Teaser = (props: {
  icon: ReactNode;
  type?: string | ReactNode;
  typeIcon?: ReactNode;
  typeColorClass?: string;
  status?: string;
  runningIcon?: ReactNode;
  statusIcon?: ReactNode;
  statusColorClass?: string;
  title: string | ReactNode;
  subtitle?: string;
  info?: ReactNode;
  links?: ReactNode[];
  actions: ReactNode;
  fullLink?: string;
}) => {
  const typeColorClass = props.typeColorClass
    ? props.typeColorClass
    : "text-neutral/80";
  const statusColorClass = props.statusColorClass
    ? props.statusColorClass
    : "text-neutral/80";

  return (
    <article className="rounded bg-white md:items-center flex flex-col md:flex-row border p-s gap-s shadow-sm hover:shadow-none">
      <LinkWrapper link={props.fullLink}>
        <div className="flex md:items-center gap-s">
          {props.icon}
          <div>
            <div className={"flex gap-xs items-center flex-wrap"}>
              {props.runningIcon}
              <h2 className="text-m text-black leading-5 text-pretty ">
                {props.title}
              </h2>
              <p className="text-neutral/70 text-sm text-s">{props.subtitle}</p>
              {props.links}
            </div>
            <div className="flex flex-wrap sm:grid-cols-8 text-s text-neutral gap-s items-center">
              {props.type && (
                <div className="flex gap-xs items-center">
                  {props.typeIcon && (
                    <div className={typeColorClass}>{props.typeIcon}</div>
                  )}
                  <p className={"font-bold pr-xs " + typeColorClass}>
                    {props.type}
                  </p>
                </div>
              )}
              {props.status && (
                <div className={"flex gap-xs items-center " + statusColorClass}>
                  {props.statusIcon}
                  <p className={"font-bold " + statusColorClass}>
                    {props.status}
                  </p>
                </div>
              )}
              {props.info}
            </div>
          </div>
        </div>
      </LinkWrapper>
      <Divider className="md:hidden" />
      <div className="flex items-center md:ml-auto justify-end gap-s flex-row-reverse md:flex-row">
        {props.actions}
      </div>
    </article>
  );
};
export default Teaser;
