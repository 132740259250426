import { FaCodeCommit } from "react-icons/fa6";

const CommitLink = (props: { label?: string; url?: string }) => {
  if (!props.label) return null;
  return (
    <a 
    onClick={(e) => e.stopPropagation()}
    href={props.url ?? ""} target="_blank" rel="noreferrer">
      <p className="bg-neutral/10 text-neutral/70 text-s px-xs rounded hover:underline flex gap-xs items-center">
        <FaCodeCommit />
        {props.label}
      </p>
    </a>
  );
};

export default CommitLink;
