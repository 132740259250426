import { Switch } from "@mui/material";

const SwitchButton = (props: {
  checked?: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  label?: string;
}) => {
  return (
    <div className="flex items-center justify-between gap-s">
      {props.label && <span>{props.label}</span>}
      <Switch
        size="medium"
        checked={props.checked}
        disabled={props.disabled}
        onChange={(e) => props.onChange(e.target.checked)}
      />
    </div>
  );
};

export default SwitchButton;
