import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Divider, FormHelperText, TextField } from "@mui/material";
import { AxiosError } from "axios";

import { sessionService } from "services";

import { Title } from "components/atoms";
import { SocialAuth } from "components/molecules";

interface RegisterProps {
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

const RegisterForm = (props: { redirectUrl: string }) => {
  const navigate = useNavigate();
  const { control, handleSubmit, getValues, formState } =
    useForm<RegisterProps>();

  const {
    mutate: register,
    isLoading,
    isError,
    error,
  } = sessionService.useRegister();

  const handleRegister = () => {
    setTimeout(() => {
      navigate(props.redirectUrl);
    }, 100);
  };

  const handleFormSubmit: SubmitHandler<RegisterProps> = (data) =>
    register(data, {
      onSuccess: handleRegister,
    });

  return (
    <div className="bg-white rounded-sm p-l">
      <Title>Register</Title>
      <form
        className="flex flex-col gap-m mt-l min-w-[350px]"
        onSubmit={handleSubmit(handleFormSubmit)}
        autoComplete="off"
      >
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextField label="Username" {...field} required autoComplete="off" />
          )}
        />
        <Divider />
        <Controller
          name="email"
          control={control}
          render={({ field }) => <TextField label="Email" {...field} required />}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField label="Password" type="password" {...field} required autoComplete="off" />
          )}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          rules={{ validate: (value) => value === getValues("password") }}
          render={({ field }) => (
            <TextField
              label="Password confirmation"
              type="password"
              required
              autoComplete="off"
              error={!!formState.errors.passwordConfirmation}
              {...field}
            />
          )}
        />
        <Button className="font-bold" variant="contained" type="submit">
          Register
        </Button>{" "}
        {isLoading && <p>Loading...</p>}
        {isError && (
          <FormHelperText error>
            {(error as AxiosError<any>).response?.data?.error}
          </FormHelperText>
        )}
        {formState.errors.passwordConfirmation && (
          <FormHelperText error>
            Password confirmation does not match
          </FormHelperText>
        )}
        <SocialAuth />
      </form>
    </div>
  );
};

export default RegisterForm;
