import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { Pager } from "components/molecules";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  content: React.ReactNode[];
  paginated?: boolean;
  pageSize?: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, content, ...other } = props;

  const pageSize = props.pageSize ?? 10;
  const pages = Math.ceil(content.length / pageSize);
  const [page, setPage] = useState(1);

  const pagedContent = useMemo(() => {
    if (!props.paginated) return content;
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    return content.slice(start, end);
  }, [content, page, pageSize, props.paginated]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        pagedContent.map((item) => <div className="m-0 mb-xs">{item}</div>)}
      {props.paginated && pages > 1 && (
        <Pager pageSize={pageSize} pages={pages} onPageChange={setPage} />
      )}
    </div>
  );
}

type tabsType = {
  label: string;
  content: React.ReactNode[];
  icon?: React.ReactNode;
}[];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExtTabs = (props: {
  tabs: tabsType;
  paginated?: boolean;
  pageSize?: number;
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // handle tabs reduction
  useEffect(() => {
    if (value > props.tabs.length - 1) {
      setValue(props.tabs.length - 1);
    } else if (value < 0) {
      setValue(0);
    }
  }, [props.tabs, value]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          {props.tabs.map((tab, index) => (
            <Tab
              key={index}
              icon={tab.icon as React.ReactElement}
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {props.tabs.map((tab, index) => (
        <CustomTabPanel
          key={index}
          value={value}
          index={index}
          content={tab.content}
          paginated={props.paginated}
          pageSize={props.pageSize}
        />
      ))}
    </>
  );
};

export default ExtTabs;
