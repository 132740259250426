import { envTypes } from "types";

import { ServiceTeaser } from "components/molecules";
import { ExtList } from "components/organisms";

const ServiceLists = (props: {
  services: envTypes.Service[];
  isLoading: boolean;
  refreshEnv: () => void;
}) => {
  return (
    <ExtList
      title={"Services"}
      items={props.services}
      loading={props.isLoading}
      refresh={props.refreshEnv}
      createEntry={(service) => <ServiceTeaser {...service} key={service.id} />}
    />
  );
};

export default ServiceLists;
