import { useCallback, useState } from "react";

import { SwipeableDrawer } from "@mui/material";
import { Sync } from "@mui/icons-material";
import { ImTerminal } from "react-icons/im";

import { repositoriesService } from "services";
import { envTypes } from "types";

import { ButtonPrimary, ButtonTertiary, Loader } from "components/atoms";
import {
  ActiveEnvTeaser,
  AvailableEnvTeaser,
  ReusableEnvTeaser,
} from "components/molecules";
import { CloneEnvForm, CreateEnvForm, ExtList } from "components/organisms";

const EnvironmentLists = (props: {
  environments: envTypes.Environment[];
  reusableEnvironments: envTypes.ReusableEnvironment[];
  loading: boolean;
  refresh: () => void;
  repoId: string;
}) => {
  const { mutate: sync, isLoading: syncLoading } = repositoriesService.useSync(
    props.repoId
  );

  const handleSync = () =>
    sync({} as unknown as void, {
      onSuccess: () => {
        props.refresh();
      },
    });

  // for create environment
  const [configuringEnv, setConfiguringEnv] =
    useState<envTypes.Environment | null>();

  const { refresh } = props;
  const ModalCustomOptions = useCallback(
    () =>
      configuringEnv ? (
        <SwipeableDrawer
          open={!!configuringEnv}
          anchor="right"
          onClose={() => {
            setConfiguringEnv(null);
          }}
          onOpen={() => {}}
        >
          <CreateEnvForm
            env={configuringEnv}
            onClose={() => {
              setConfiguringEnv(null);
              refresh();
            }}
          />
        </SwipeableDrawer>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configuringEnv, props.refresh]
  );

  // for clone environment
  const [cloneEnv, setCloneEnv] = useState<envTypes.Environment | null>();

  const ModalCloneCustomOptions = useCallback(
    () =>
      cloneEnv ? (
        <SwipeableDrawer
          open={!!cloneEnv}
          anchor="right"
          onClose={() => {
            setCloneEnv(null);
          }}
          onOpen={() => {}}
        >
          <CloneEnvForm
            env={cloneEnv}
            repoId={props.repoId}
            onClose={() => {
              setCloneEnv(null);
              props.refresh();
            }}
          />
        </SwipeableDrawer>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cloneEnv, props.repoId, props.refresh]
  );

  // for cli setup
  const [cliSetup, setCliSetup] = useState<boolean>(false);
  const ModalCLISetup = useCallback(
    () =>
      cliSetup ? (
        <SwipeableDrawer
          open={cliSetup}
          anchor="right"
          onClose={() => {
            setCliSetup(false);
          }}
          onOpen={() => {}}
        >
          <div className="p-m">
            <h2 className="text-lg font-semibold">Qaack CLI Setup</h2>
            <p className="text-sm text-gray-500 pb-m">
              Follow the steps below to setup the CLI
            </p>
            <ol className="list-decimal list-inside">
              <li className="py-s">
                Install the CLI. Instructions are{" "}
                <a
                  className="underline"
                  href="https://docs.qaack.cloud/cli/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </li>
              <li>
                <i>(Skip if logged in)</i> Login to your instance
              </li>
              <pre className="bg-gray-100 p-s rounded-md ">
                {`$ qck login -i ${window.location.protocol}//${window.location.host}`}
              </pre>
              <li className="pt-s">Go to your repository directory</li>
              <li className="pt-s">Setup this project</li>
              <pre className="bg-gray-100 p-s rounded-md">
                {`$ qck setup ${window.location.protocol}//${window.location.host} ${props.repoId}`}
              </pre>
              <li className="pt-s">Confirm that it works</li>
              <pre className="bg-gray-100 p-s rounded-md">{`$ qck status`}</pre>
              <li className="pt-s">Enjoy!</li>
            </ol>
          </div>
        </SwipeableDrawer>
      ) : null,
    [cliSetup, props.repoId]
  );

  return (
    <div className="grid md:grid-cols-2 gap-m">
      <div className="col-span-2 flex gap-s items-center bg-white p-s rounded shadow-sm">
        <ButtonPrimary
          className="rounded-full"
          onClick={handleSync}
          disabled={syncLoading}
        >
          {syncLoading ? <Loader /> : <Sync />} &nbsp; Sync
        </ButtonPrimary>
        <ButtonTertiary
          className="rounded-full"
          onClick={() => setCliSetup(true)}
        >
          <ImTerminal /> &nbsp; Setup CLI
        </ButtonTertiary>
      </div>
      <div className="col-span-2">
        <ExtList
          title="Created"
          loading={props.loading}
          items={props?.environments.filter((env) => env.status !== "empty")}
          createEntry={(env: envTypes.Environment) => (
            <ActiveEnvTeaser
              {...env}
              refreshRepo={props.refresh}
              cloneEnv={() => setCloneEnv(env)}
              key={env.id}
            />
          )}
          emptyMsg={"No created environments! Start creating one!"}
          searchEnabled
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <ExtList
          title="Available"
          paginated
          pageSize={10}
          loading={props.loading}
          items={props?.environments.filter((env: envTypes.Environment) => {
            return env.status === "empty";
          })}
          emptyMsg={"No available environments! Try syncing first!"}
          notFoundMsg={"No environments found!"}
          createEntry={(env: envTypes.Environment) => (
            <AvailableEnvTeaser
              {...env}
              refreshRepo={props.refresh}
              key={env.id}
              configureCreate={() => setConfiguringEnv(env)}
            />
          )}
          tabBy="gitRef.type"
          searchEnabled
        />
        <ModalCustomOptions />
        <ModalCloneCustomOptions />
        <ModalCLISetup />
      </div>
      <div className="col-span-2 md:col-span-1">
        <ExtList
          title="Reusable"
          items={props?.reusableEnvironments}
          emptyMsg={"No reusable environments! Start creating one!"}
          createEntry={(reusableEnvironment: envTypes.ReusableEnvironment) => (
            <ReusableEnvTeaser
              {...reusableEnvironment}
              refreshRepo={props.refresh}
              key={reusableEnvironment.id}
            />
          )}
          searchEnabled
        />
      </div>
    </div>
  );
};

export default EnvironmentLists;
