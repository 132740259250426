import { Pagination } from "@mui/material";
import { useState } from "react";

const Pager = (props: {
  pageSize: number;
  pages: number;
  onPageChange: (page: number) => void;
}) => {
  const [page, setPage] = useState(1);

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    props.onPageChange(page);
  };

  return (
    <Pagination
      className="my-s mx-auto"
      variant="outlined"
      shape="rounded"
      page={page}
      onChange={onPageChange}
      count={props.pages}
    />
  );
};

export default Pager;
