import { ReactNode, createContext, useEffect, useState } from "react";
import { axiosInstance } from "services/config";
import { notificationTypes } from "types";

const initialState = {
  notifications: [] as notificationTypes.Notification[],
  removeNotification: (id: string) => {},
  addNotification: (
    notification: Partial<notificationTypes.Notification>
  ) => {},
  clearAllNotifications: () => {},
};

export const Context = createContext(initialState);

export function NotificationsContext(props: { children: ReactNode }) {
  const [notifications, setNotifications] = useState<
    notificationTypes.Notification[]
  >([]);

  const addNotification = (
    notification: Partial<notificationTypes.Notification>
  ) => {
    setNotifications((notifications) => [
      ...notifications,
      {
        type: notification.type,
        message: notification.message,
        id: Date.now().toFixed(),
      } as notificationTypes.Notification,
    ]);
  };
  const removeNotification = (id: string) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.id !== id)
    );
  };
  const clearAllNotifications = () => {
    setNotifications([]);
  };

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => {
        if (response.data?.info) {
          addNotification({
            type: "info",
            message: response.data.info,
          });
        }
        if (response.data?.success) {
          addNotification({
            type: "success",
            message: response.data.success,
          });
        }
        return response;
      },
      (error) => {
        if (error.response?.data?.error) {
          addNotification({
            type: "error",
            message: error?.response.data.error,
          });
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, []);

  return (
    <Context.Provider
      value={{
        notifications,
        removeNotification,
        addNotification,
        clearAllNotifications,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
