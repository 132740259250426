const package_json = require("../../../package.json");

const Footer = () => {
  return (
    <div className="w-full px-s flex text-neutral font-bold text-s justify-end gap-m mx-auto">
      <p>© 2024 Qaack</p>
      <p>
        {package_json.name} {package_json.version}
      </p>
    </div>
  );
};

export default Footer;
