import { useCallback, useContext, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { Button, FormHelperText, TextField } from "@mui/material";

import { sessionService } from "services";
import { UserState } from "store";
import { AxiosError } from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Title } from "components/atoms";
import { SocialAuth } from "components/molecules";

interface LogInProps {
  email: string;
  password: string;
}

const LogInForm = () => {
  const navigate = useNavigate();
  // get ?redirectTo=... from url using react router
  const [searchParams] = useSearchParams();
  const redirectTo = useMemo(
    () => searchParams.get("redirectTo"),
    [searchParams]
  );

  const uContext = useContext(UserState);
  const { control, handleSubmit } = useForm<LogInProps>();

  const {
    mutate: logIn,
    isLoading,
    isError,
    error,
  } = sessionService.useLogIn();

  const handleLogIn = useCallback(
    (token: string) => {
      Cookies.set("token", token);
      uContext.refetch();
      setTimeout(() => {
        const to = redirectTo ? redirectTo : "/";
        navigate(to);
      }, 100);
    },
    [navigate, uContext, redirectTo]
  );

  const handleFormSubmit: SubmitHandler<LogInProps> = useCallback(
    (data) =>
      logIn(data, {
        onSuccess: (r) => {
          handleLogIn(r.token);
        },
      }),
    [logIn, handleLogIn]
  );

  return (
    <div className="bg-white rounded-sm p-l">
      <Title>Log In</Title>
      <form
        className="flex flex-col gap-m min-w-[350px] mt-l"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => <TextField label="Email" {...field} />}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField label="Password" type="password" {...field} />
          )}
        />
        <Button className="font-bold" variant="contained" type="submit">
          Log in
        </Button>{" "}
        {isLoading && <p>Loading...</p>}
        {isError && (
          <FormHelperText error>
            {(error as AxiosError<any>).response?.data?.error}
          </FormHelperText>
        )}
        <SocialAuth />
      </form>
    </div>
  );
};

export default LogInForm;
