import { userTypes } from "types";
import { useLocalStorage } from "./localStorage";
import { useCallback, useEffect, useMemo } from "react";

const useRecentlyVisited = (
  content: Partial<userTypes.User["recentlyVisited"][0]>
) => {
  const [recentlyVisited, setRecentlyVisited] = useLocalStorage(
    "recentlyVisited",
    []
  );

  const addRecentlyVisited = useCallback(
    (data: userTypes.User["recentlyVisited"][0]) => {
      const newRecentlyVisited = recentlyVisited.filter(
        (item: userTypes.User["recentlyVisited"][0]) =>
          item.visitedId !== data.visitedId
      );
      newRecentlyVisited.unshift(data);
      // if more than 6 items, remove the last one
      if (newRecentlyVisited.length > 10) newRecentlyVisited.pop();
      setRecentlyVisited(newRecentlyVisited);
    },
    [recentlyVisited, setRecentlyVisited]
  );

  const lastAdded = useMemo(() => recentlyVisited[0], [recentlyVisited]);

  useEffect(() => {
    if (
      content.data &&
      content.visitedId &&
      lastAdded?.visitedId !== content.visitedId
    ) {
      addRecentlyVisited(content as userTypes.User["recentlyVisited"][0]);
    }
  }, [content, lastAdded, addRecentlyVisited]);

  return { recentlyVisited, addRecentlyVisited, lastAdded };
};


export { useRecentlyVisited };
