import { Alert, Button } from "@mui/material";
import { Link } from "react-router-dom";

import NormalPage from "./layout/page";

export default function NotFound() {
  return (
    <NormalPage>
      <div className="flex flex-col p-10 items-center h-full">
        <section>
          <div className="py-l px-m mx-auto max-w-screen-xl lg:py-xl lg:px-m">
            <div className="mx-auto max-w-screen-sm text-center">
              <h1 className="mb-xs text-[60px] lg:text-[90px] tracking-tight font-extrabold text-brand">
                404
              </h1>
              <p className="mb-s text-xl tracking-tight font-bold text-neutral">
                NOT FOUND
              </p>
              <p className="mb-l text-m font-light text-neutral">
                We couldn't find the page you were looking for. You can get back
                to:
              </p>
              <div className="flex gap-l justify-center">
                <Link to="/">
                  <Button variant="outlined">Welcome</Button>
                </Link>
                <Link to="/profile">
                  <Button variant="outlined">Profile</Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </NormalPage>
  );
}
