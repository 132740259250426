import { useCallback, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Backdrop } from "@mui/material";
import Cookies from "js-cookie";

import { sessionService } from "services";
import { UserState } from "store";

import { Loader } from "components/atoms";

const AutoLogin = () => {
  // use params from router user and password to do an automatic login and then redirect to url
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // do login
  const {
    mutate: logIn,
    isLoading,
    isError,
    error,
  } = sessionService.useLogIn();
  const uContext = useContext(UserState);

  const handleLogIn = useCallback(
    (token: string) => {
      Cookies.set("token", token);
      uContext.refetch();
      const to = searchParams.get("redirectTo") ?? "/groups";
      setTimeout(() => {
        navigate(to);
      }, 100);
    },
    [navigate, searchParams, uContext]
  );

  useEffect(() => {
    logIn(
      {
        email: searchParams.get("email") ?? "missing",
        password: searchParams.get("password") ?? "missing",
      },
      {
        onSuccess: (r) => {
          handleLogIn(r.token);
        },
      }
    );
  }, [searchParams, logIn, handleLogIn]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col gap-s">
        {isLoading && (
          <Backdrop open={true}>
            <div className="flex gap-s">
              <Loader />
              <h1>Logging in </h1>
              <p>{searchParams.get("email") ?? "missing"} </p>
            </div>
          </Backdrop>
        )}
        {isError && (
          <p className="text-lg text-red-700">Error: {error.message}</p>
        )}
      </div>
    </div>
  );
};

export default AutoLogin;
