import { ReactNode, createContext } from "react";

import { sessionService } from "services";
import { userTypes } from "types";

export const Context = createContext({
  logged: false,
  userInfo: {} as userTypes.User | any,
  isLoading: true,
  refetch: () => {},
});

export function UserContext(props: { children: ReactNode }) {
  const { data, isLoading, isError, refetch } = sessionService.useUser();

  return (
    <Context.Provider
      value={{
        logged: !isError && !isLoading && !!data,
        userInfo: {
          ...data,
          username: data?.username === "" ? undefined : data?.username,
        },
        isLoading: isLoading,
        refetch: refetch,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
