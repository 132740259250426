export const Drupal10 = `services:
  db:
    image: qaack/mariadb

  web: 
    image: qaack/nginx-php81
    default: true
    checkout: true
    commands:
      provision:
        # this step setup the server for get the drupal docroot
        - echo "-- provisioning webserver"
        - rm -rf /usr/share/nginx/html
        - ln -s $(pwd)/web/ /usr/share/nginx/html
        - mkdir web/sites/default/files
        - chown www-data:www-data web/sites/default/files
      install_drupal:
        - echo "-- installing drupal"
        - composer install
      import_db:
        - echo "-- importing db"
        - cat db.sql.gz | gunzip | ./vendor/drush/drush/drush sqlc
      finish:
        - ./vendor/drush/drush/drush cr
        - echo 'export PATH=$PATH:'$(pwd)'/vendor/bin' >> ~/.bashrc
`;

export const React = `services:
  web:
    image: node
    default: true
    checkout: true
    port: 3000
    start: npm run start
    commands:
      dependencies:
        - npm ci
        - npm run build
`

