import { SubmitHandler } from "react-hook-form";

import { envTypes } from "types";
import { repositoriesService } from "services";

import OptionsEnvForm from "./OptionsEnvForm";

const CloneEnvForm = (props: {
  repoId: string;
  env: envTypes.Environment;
  onClose: () => void;
}) => {
  const {
    mutate: clone,
    isLoading: cloneLoading,
    error: cloneError,
  } = repositoriesService.useSpawnEnv(props.repoId);

  const {
    data: repo,
  } = repositoriesService.useRepo(props.repoId);

  const handleFormSubmit: SubmitHandler<envTypes.CloneEnvProps> = (data) => {
    const ttlNumber = Number(data.ttl);
    var options: envTypes.CloneEnvProps = {
      gitRef: props.env.gitRef,
      build: data.build,
      name: data.name || undefined,
      ttl: !isNaN(ttlNumber) ? ttlNumber : undefined,
      notReuse: data.notReuse ? true : undefined,
      reusableLabel: data.reusableLabel || undefined,
    };

    clone(options, {
      onSuccess: () => {
        setTimeout(props.onClose, 500);
      },
    });
  };

  return (
    <OptionsEnvForm
      title={"Clone environment"}
      subtitle={props.env.gitRef.name}
      buttonText={"Clone"}
      loadingText={"Cloning..."}
      error={cloneError?.message}
      loading={cloneLoading}
      onSubmit={handleFormSubmit}
      reusables={repo?.reusableEnvironments}
    />
  );
};

export default CloneEnvForm;
