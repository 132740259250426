import { useNavigate, useParams } from "react-router";
import { useMemo } from "react";
import {
  Adjust,
  ArrowBack,
  Group,
  Inventory,
} from "@mui/icons-material";

import {
  environmentsService,
  groupsService,
  repositoriesService,
} from "services";

export default function Breadcrumbs() {
  let navigate = useNavigate();

  const params = useParams();

  // check if url contains profile
  const isProfile = window.location.pathname.includes("/profile");
  // check if url contains groups
  const isGroups = window.location.pathname.includes("/groups");
  // check if url contains dashboard
  const isDashboard = window.location.pathname.includes("/dashboard");

  const { data: groupName } = groupsService.useGroupName(params.groupId);
  const { data: repoName } = repositoriesService.useRepoName(params.repoId);
  const { data: envName } = environmentsService.useEnvName(params.envId);
  const { data: serviceName } = environmentsService.useServiceName(
    params.servId
  );

  const breadcrumbs = useMemo(() => {
    const crumbs = [];
    if (groupName && repoName)
      crumbs.push({
        icon: <Group fontSize="small" />,
        text: groupName,
        href: "/groups/" + params.groupId + "/repos",
      });
    if (repoName && envName)
      crumbs.push({
        icon: <Inventory className="!h-m" fontSize="small" />,
        text: repoName,
        href: "/groups/" + params.groupId + "/repos/" + params.repoId + "/envs",
      });
    if (envName && serviceName)
      crumbs.push({
        icon: <Adjust fontSize="small" />,
        text: envName,
        href:
          "/groups/" +
          params.groupId +
          "/repos/" +
          params.repoId +
          "/envs/" +
          params.envId,
      });
    return crumbs;
  }, [
    groupName,
    params.groupId,
    params.repoId,
    params.envId,
    repoName,
    envName,
    serviceName,
  ]);

  return (
    <div className="flex gap-x-m flex-wrap text-s items-center h-xl mb-s">
      {(breadcrumbs.length > 1 || isProfile || isGroups || isDashboard) && (
        <span
          onClick={() => {
            navigate(breadcrumbs[breadcrumbs.length - 2]?.href ?? "/");
          }}
          className="hover:text-black text-neutral/80 cursor-pointer gap-xs pr-m rounded border-r-2 flex items-center"
        >
          <ArrowBack className="!h-m" />
          Back
        </span>
      )}
      {breadcrumbs?.map(
        (crumb, index) =>
          crumb && (
            <span
              onClick={() => {
                navigate(crumb.href);
              }}
              key={crumb.text}
              className="flex items-center gap-xs hover:text-black text-neutral/80 cursor-pointer pr-m rounded"
            >
              {crumb.icon}
              {crumb.text}
            </span>
          )
      )}
    </div>
  );
}
