import { Link } from "react-router-dom";
import { Button } from "@mui/material";

import { LogInForm } from "components/organisms";

import CenteredPage from "./layout/centered";
import icon from "assets/svg/icon-green.svg";

export default function LogIn() {
  return (
    <CenteredPage>
      <div className="flex items-center flex-col my-xl gap-s">
        <div className="flex w-full justify-between gap-m items-center cursor-pointer col-span-2 h-xl px-s">
          <span className="flex gap-s items-center font-mono tracking-wider text-[18px] text-transparent bg-clip-text bg-gradient-to-r to-brand-light from-brand">
            <img src={icon} className="h-[45px]" alt="icon" />
            <b className="hidden sm:block">QAACK</b>
          </span>
        </div>
        <LogInForm />
        <div className="flex gap-s items-center justify-between w-full px-s">
          Not registered yet?{" "}
          <Link to="/register">
            <Button>Register now</Button>
          </Link>
        </div>
      </div>
    </CenteredPage>
  );
}
