import { Alert, Button } from "@mui/material";
import { useContext } from "react";
import { NotificationsState } from "store";

const NotificationList = () => {
  const { notifications, removeNotification, clearAllNotifications } =
    useContext(NotificationsState);
  return (
    <div className="flex flex-col gap-xs mx-s w-80">
      {notifications.length === 0 && (
        <Alert severity="info">You don't have notifications</Alert>
      )}
      {notifications.length > 1 && (
        <div className="flex justify-center">
          <Button variant="text" onClick={clearAllNotifications}>
            Clear all
          </Button>
        </div>
      )}

      {notifications
        .sort((a, b) => Number(b.id) - Number(a.id))
        .map(
          (notification) =>
            notification && (
              <Alert
                severity={notification.type}
                key={"notification-" + notification.id}
                onClose={() => removeNotification(notification.id)}
              >
                {notification.message}
              </Alert>
            )
        )}
    </div>
  );
};

export default NotificationList;
