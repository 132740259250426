import React, { useState } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button } from "@mui/material";

import { Loader } from ".";

interface Props {
  options: { [label: string]: { action: () => void; icon?: React.ReactNode } };
  status?: React.ReactNode;
  variant?: "contained" | "outlined" | "text";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
}

const SplitButton: React.FC<Props> = ({
  options,
  status,
  variant,
  size,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (action: () => void) => {
    handleClose();
    action();
  };

  const labels = Object.keys(options);
  const primaryLabel = labels[0];
  const secondaryLabels = labels.slice(1);

  return (
    <ButtonGroup size={size ?? "small"} disabled={disabled}>
      <Button
        variant={variant ?? "contained"}
        disabled={status !== undefined}
        onClick={() => handleButtonClick(options[primaryLabel].action)}
        startIcon={status ? <Loader size="small" /> : options[primaryLabel].icon}
      >
        {status ? status : primaryLabel}
      </Button>
      {secondaryLabels.length > 0 && (
        <>
          <Button
            variant={variant ?? "contained"}
            onClick={handleClick}
            disabled={status !== undefined}
          >
            <ArrowDropDownIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {secondaryLabels.map((label) => (
              <MenuItem
                key={label}
                className="!text-s !uppercase !text-brand"
                onClick={() => handleButtonClick(options[label].action)}
              >
                {!!options[label].icon && <>{options[label].icon} &nbsp;</>}
                {label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </ButtonGroup>
  );
};

export default SplitButton;
