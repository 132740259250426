import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

import { axiosInstance } from "./config";
import { groupTypes, repoTypes } from "types";

const requests = {
  getGroups: () =>
    axiosInstance.get("/groups/").then((response) => response.data),
  getGroup: (groupId?: string) =>
    axiosInstance
      .get("/group/" + (groupId || "missing"))
      .then((response) => response.data),
  getGroupName: (groupId?: string) =>
    groupId
      ? axiosInstance
          .get("/group/" + (groupId || "missing"))
          .then((response) => response.data.name)
      : undefined,
  getGroupSettings: (groupId?: string) =>
    axiosInstance
      .get("/group/" + (groupId || "missing") + "/settings")
      .then((response) => response.data),
  createGroupVariable: (groupId?: string, newVariable?: any) =>
    axiosInstance
      .post("/group/" + (groupId || "missing") + "/variables", newVariable)
      .then((response) => response.data),
  removeVariable: (groupId?: string, variableName?: string) =>
    axiosInstance
      .delete("/group/" + (groupId || "missing") + "/variables/" + variableName)
      .then((response) => response.data),

  addMember: (groupId: string, memberEmail: string) =>
    axiosInstance
      .post(`/group/${groupId}/members/`, { email: memberEmail })
      .then((response) => response.data),
  removeMember: (groupId: string, memberEmail: string) =>
    axiosInstance
      .delete(`/group/${groupId}/members/${memberEmail}`)
      .then((response) => response.data),
};

const groupsService = {
  useGroups: () => useQuery<groupTypes.Group[], AxiosError>("getGroups", requests.getGroups),

  useGroup: (groupId?: string) =>
    useQuery<groupTypes.Group, AxiosError>("getGroup" + (groupId || "missing"), () =>
      requests.getGroup(groupId), { enabled: !!groupId}
    ),

  useGroupName: (groupId?: string) =>
    useQuery<any, AxiosError>("getGroupName" + (groupId || "missing"), () =>
      requests.getGroupName(groupId)
    ),

  useGroupSettings: (groupId?: string) =>
    useQuery<any, AxiosError>("getGroupSettings" + (groupId || "missing"), () =>
      requests.getGroupSettings(groupId)
    ),

  useCreateGroupVariable: (groupId?: string) =>
    useMutation<any, AxiosError, repoTypes.Variable>(
      "createGroupVariable",
      (newVariable?: repoTypes.Variable) =>
        requests.createGroupVariable(groupId, newVariable)
    ),
  useRemoveVariable: (groupId?: string) =>
    useMutation<any, AxiosError, string>(
      "removeVariable" + (groupId || "missing"),
      (variableName) => requests.removeVariable(groupId, variableName)
    ),

  useAddMember: (groupId: string) =>
    useMutation<any, AxiosError, string>(
      "addMember" + groupId,
      (memberEmail: string) => requests.addMember(groupId, memberEmail)
    ),
  useRemoveMember: (groupId: string) =>
    useMutation<any, AxiosError, string>(
      "removeMember" + groupId,
      (memberEmail: string) => requests.removeMember(groupId, memberEmail)
    ),
};

export default groupsService;
