import { ReactNode } from "react";

export default function CenteredPage(props: {
  title?: ReactNode;
  tabs?: ReactNode[];
  children: ReactNode;
  hideBreadCrumbs?: boolean;
}) {
  return (
    <div className="flex h-screen justify-center items-center bg-neutral/5 overflow-hidden">
      {/* Main content */}
      <main className="flex flex-col grow justify-between items-center gap-0 overflow-y-auto py-auto mb-xl">
        {props.children}
      </main>
    </div>
  );
}