import { useCallback, useState } from "react";
import {
  ContentPaste,
  ControlPointDuplicate,
  Delete,
  Hardware,
  Launch,
  Stop,
  Tune,
} from "@mui/icons-material";
import { IconButton, SwipeableDrawer } from "@mui/material";

import { envTypes } from "types";
import { environmentsService } from "services";

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
  SplitButton,
  Loader,
} from "components/atoms";
import { CloneEnvForm, CreateEnvForm } from "components/organisms";

import EnvQuickActions from "./quickActions";

// actions
const EnvActions = (env: envTypes.Environment) => {
  const { mutate: create, isLoading: createLoading } =
    environmentsService.useCreate(env.id);
  const handleCreateAndBuild = () => {
    var options: envTypes.CreateEnvProps = {
      build: true,
    };
    create(options);
  };

  const { mutate: build, isLoading: loadingBuild } =
    environmentsService.useBuild(env.id);
  const handleBuild = () => build();

  const { mutate: stopBuild, isLoading: stopBuildLoading } =
    environmentsService.useStopBuild(env.id);
  const handleStopBuild = () => stopBuild();

  const { mutate: rebuild, isLoading: loadingRebuild } =
    environmentsService.useRebuild(env.id);
  const handleRebuild = () => rebuild();

  const { mutate: remove, isLoading: removeLoading } =
    environmentsService.useRemove(env.id);
  const handleRemove = () => remove();

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(env?.weburl ?? "No link available");
  }, [env?.weburl]);

  // for clone environment
  const [cloneEnv, setCloneEnv] = useState<envTypes.Environment | null>();
  const ModalCloneCustomOptions = useCallback(
    () =>
      cloneEnv ? (
        <SwipeableDrawer
          open={!!cloneEnv}
          anchor="right"
          onClose={() => {
            setCloneEnv(null);
          }}
          onOpen={() => {}}
        >
          <CloneEnvForm
            env={cloneEnv}
            repoId={env.id ?? ""}
            onClose={() => {
              setCloneEnv(null);
            }}
          />
        </SwipeableDrawer>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cloneEnv]
  );

  const CloneButton = (
    <>
      <ButtonSecondary
        startIcon={<ControlPointDuplicate />}
        onClick={() => setCloneEnv(env)}
      >
        Clone
      </ButtonSecondary>
      <ModalCloneCustomOptions />
    </>
  );

  const LaunchEnvButton = (
    <SplitButton
      variant="outlined"
      disabled={!env?.weburl}
      options={Object.keys(env?.aliases ?? []).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            action: () =>
              window.open(env.aliases ? env.aliases[key] : "", "_blank"),
            icon: <Launch />,
          },
        }),
        {
          launch: {
            action: () => window.open(env?.weburl, "_blank"),
            icon: <Launch />,
          },
        }
      )}
    />
  );

  const RebuildButton = (
    <ButtonPrimary
      startIcon={loadingRebuild ? <Loader size="small" /> : <Hardware />}
      onClick={handleRebuild}
      disabled={loadingRebuild}
    >
      Rebuild
    </ButtonPrimary>
  );
  const CreateAndBuildButton = (
    <ButtonPrimary
      startIcon={createLoading ? <Loader size="small" /> : <Hardware />}
      onClick={handleCreateAndBuild}
      disabled={loadingBuild}
    >
      Build
    </ButtonPrimary>
  );
  const BuildButton = (
    <ButtonPrimary
      startIcon={loadingBuild ? <Loader size="small" /> : <Hardware />}
      onClick={handleBuild}
      disabled={loadingBuild}
    >
      Build
    </ButtonPrimary>
  );
  const StopBuildButton = (
    <ButtonPrimary
      startIcon={stopBuildLoading ? <Loader size="small" /> : <Stop />}
      color="error"
      onClick={handleStopBuild}
    >
      Cancel
    </ButtonPrimary>
  );

  // modals
  const [configuringEnv, setConfiguringEnv] =
    useState<envTypes.Environment | null>();
  const ModalCustomOptions = useCallback(
    () =>
      configuringEnv ? (
        <SwipeableDrawer
          open={!!configuringEnv}
          anchor="right"
          onClose={() => {
            setConfiguringEnv(null);
          }}
          onOpen={() => {}}
        >
          <CreateEnvForm
            env={configuringEnv}
            onClose={() => {
              setConfiguringEnv(null);
            }}
          />
        </SwipeableDrawer>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configuringEnv]
  );

  const CreateButton = (
    <>
      <ButtonSecondary
        startIcon={<Tune />}
        onClick={() => setConfiguringEnv(env)}
      >
        Configure
      </ButtonSecondary>
      <ModalCustomOptions />
    </>
  );
  const DestroyButton = (
    <IconButton
      onClick={handleRemove}
      color="error"
      className="!ml-auto"
      size="small"
    >
      {removeLoading ? <Loader size="small" /> : <Delete />}
    </IconButton>
  );
  const CopyLinkButton = (
    <ButtonTertiary startIcon={<ContentPaste />} onClick={handleCopyLink}>
      Copy link
    </ButtonTertiary>
  );

  let actions: JSX.Element[] = [];
  switch (env.status) {
    case "empty":
      actions = [CreateAndBuildButton, CreateButton, CloneButton];
      break;
    case "creating":
      actions = [CloneButton];
      break;
    case "created":
      actions = [
        BuildButton,
        CloneButton,
        LaunchEnvButton,
        CopyLinkButton,
        DestroyButton,
      ];
      break;
    case "queued":
    case "building":
      actions = [StopBuildButton, CloneButton, LaunchEnvButton, CopyLinkButton];
      break;
    case "built":
    case "failed":
    case "cancelled":
      actions = [
        RebuildButton,
        LaunchEnvButton,
        CloneButton,
        CopyLinkButton,
        ...EnvQuickActions(env),
        DestroyButton,
      ];
      break;
    default:
      actions = [];
  }
  return <>{actions}</>;
};

export default EnvActions;
