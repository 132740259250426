import { useMemo, useState } from "react";
import { Alert, Modal, Tooltip } from "@mui/material";
import { Science } from "@mui/icons-material";

import { testTypes } from "types";
import { TestStatusIcon } from "components/atoms";

import Teaser from "./baseTeaser";
import CircularProgressWithLabel from "../circularProgress";

const ImageResult = (result: testTypes.TestResult & { testId: string }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const src = `/api/test/${result.testId}/result/${result.label}`;

  return (
    <div className="relative">
      <img
        src={src}
        alt={result.label}
        onClick={() => setFullScreen(!fullScreen)}
        className="!h-xl w-auto hover:cursor-pointer"
      />
      <Modal open={fullScreen} onClose={() => setFullScreen(false)}>
        <img
          src={src}
          alt={result.label}
          className="bg-white p-m rounded w-auto h-2/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      </Modal>
    </div>
  );
};

const PercentageResult = (result: testTypes.TestResult) => {
  const percentage = Number(result.value);
  const color =
    percentage > 80 ? "primary" : percentage > 50 ? "warning" : "error";
  return (
    <CircularProgressWithLabel
      color={color}
      size={50}
      values={[
        {
          value: percentage,
          label: result.label,
        },
      ]}
      label={result.label}
    />
  );
};

const NumberResult = (result: testTypes.TestResult) => {
  const number = Number(result.value);
  return (
    <div className="flex flex-col items-center">
      <p className="text-neutral text-m">{number}</p>
      <p className="text-neutral text-m">{result.label}</p>
    </div>
  );
};

const TestResultFactory = (
  result: testTypes.TestResult & { testId: string }
) => {
  switch (result.type) {
    case "image":
      return <ImageResult {...result} />;
    case "number":
      return <NumberResult {...result} />;
    case "percentage":
      return <PercentageResult {...result} />;
    case "error":
      return (
        <Alert severity="error">
          <Tooltip title={result.value}>
            <p>{result.value.toString().slice(0, 30)}...</p>
          </Tooltip>
        </Alert>
      );
    default:
      return (
        <p>
          {result.label}: {result.value}
        </p>
      );
  }
};

const TestTeaser = (test: testTypes.Test) => {
  const statusClasses = useMemo(
    () =>
      test.status === "failed" || test.results?.some((r) => r.type === "error")
        ? "!fill-status-error !text-status-error"
        : test.status === "finished"
        ? "!fill-status-success !text-status-success"
        : test.status === "running"
        ? "!fill-status-warning !text-status-warning"
        : "!fill-neutral !text-status-neutral",
    [test.status, test.results]
  );

  return (
    <Teaser
      icon={<Science className={statusClasses} />}
      title={test.label}
      type={test.type}
      status={test.status}
      statusIcon={<TestStatusIcon status={test.status} />}
      statusColorClass={statusClasses}
      actions={
        <>
          {test.results?.map((result: testTypes.TestResult) => (
            <TestResultFactory
              key={result.label}
              {...result}
              testId={test.id}
            />
          ))}
        </>
      }
    />
  );
};

export { TestTeaser };
