import { useMemo } from "react";
import prettyBytes from "pretty-bytes";

import { groupTypes } from "types";

const GroupInfo = (
  group: groupTypes.Group & { mode: "teaser" | "details" }
) => {
  const textClasses = useMemo(() => {
    const baseClasses = "text-neutral";
    switch (group.mode) {
      case "teaser":
        return `${baseClasses} text-sm`;
      case "details":
        return `${baseClasses} text-m`;
    }
  }, [group.mode]);

  const infoItems = [];

  if (!!group.size) infoItems.push(`occupies ${prettyBytes(group.size)}`);
  if (group.repoCount) infoItems.push(`has ${group.repoCount} repos`);

  return (
    <>
      {infoItems.map((item, index) => (
        <p key={index} className={textClasses}>
          {item}
          {index < infoItems.length - 1 && ","}
        </p>
      ))}
    </>
  );
};

export default GroupInfo;
