import { ReactNode, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { Backdrop, SwipeableDrawer } from "@mui/material";

import { UserState } from "store";

import { Breadcrumbs, Loader } from "components/atoms";

import Footer from "./footer";
import Sidebar from "./sidebar";

export default function NormalPage(props: {
  title?: ReactNode;
  tabs?: ReactNode[];
  children: ReactNode;
  hideBreadCrumbs?: boolean;
}) {
  const uContext = useContext(UserState);
  const isLogin = window.location.pathname === "/login";
  const isRegister = window.location.pathname === "/register";
  const isActivate = window.location.pathname.includes("/activate");

  const redirectTo = window.location.pathname + window.location.search;

  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

  return (
    <div className="flex flex-col md:flex-row h-screen bg-neutral/5 overflow-hidden">
      {/* NavBar */}
      {/* <Header /> */}

      {/* SideBar */}
      <Sidebar openMobile={() => setMobileSidebarOpen(true)} />

      {/* Mobile Sidebar */}
      <SwipeableDrawer
        anchor="left"
        open={mobileSidebarOpen}
        onClose={() => setMobileSidebarOpen(false)}
        onOpen={() => setMobileSidebarOpen(true)}
      >
        <Sidebar mobile />
      </SwipeableDrawer>

      {/* Main content */}
      <main className="flex bg-neutral/5 flex-col !h-full grow justify-between gap-0 overflow-y-auto items-center">
        <div className="container px-s md:px-m lg:px-l xl:px-xl 2xl:px-[120px] mx-0 grow">
          {uContext.isLoading ? (
            <Backdrop open={true} className="z-10">
              <Loader/>
            </Backdrop>
          ) : uContext.logged || isLogin || isRegister || isActivate ? (
            <>
              {props.hideBreadCrumbs && (
                <div className="pt-l pb-m min-h-[24px]" />
              )}
              {!isLogin && !isRegister && !props.hideBreadCrumbs && (
                <Breadcrumbs />
              )}
              {props.title && (
                <div className="flex justify-between items-end border-b-4 border-title py-0 mb-m flex-wrap bg-white px-s pt-s rounded">
                  <div className="py-s">{props.title}</div>
                  <div className="flex gap-xs -mb-xs">{props.tabs}</div>
                </div>
              )}
              {props.children}
            </>
          ) : (
            <Navigate
              to={`/login?redirectTo=${encodeURIComponent(redirectTo)}`}
            />
          )}
        </div>
        <Footer />
      </main>
    </div>
  );
}
