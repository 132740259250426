import { useMemo } from "react";
import {
  Add,
  Adjust,
  ContentPaste,
  ControlPointDuplicate,
  Delete,
  Hardware,
  Launch,
  Stop,
  Tune,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import prettyBytes from "pretty-bytes";

import { environmentsService, repositoriesService } from "services";
import { envTypes } from "types";
import { getTimeDeltaString } from "utils";

import {
  CommitLink,
  GitRefIcon,
  MergeRequestTitle,
  PullRequestChip,
  SplitButton,
  EnvStatusIcon,
  EnvRunningStatusIcon,
} from "components/atoms";
import { EnvInfo } from "components/molecules";
import Teaser from "./baseTeaser";
import { useParams } from "react-router-dom";

const AvailableEnvTeaser = (
  env: envTypes.Environment & {
    refreshRepo: () => void;
    configureCreate: () => void;
  }
) => {
  const { mutate: create, isLoading: createLoading } =
    environmentsService.useCreate(env.id);

  const handleCreateAndBuild = () => {
    var options: envTypes.CreateEnvProps = {
      build: true,
    };

    create(options, {
      onSuccess: () => {
        env.refreshRepo();
      },
    });
  };

  const handleCreateOnly = () => {
    var options: envTypes.CreateEnvProps = {
      build: false,
    };

    create(options, {
      onSuccess: () => {
        env.refreshRepo();
      },
    });
  };

  return (
    <Teaser
      icon={<GitRefIcon {...env} size="large" />}
      links={[
        <PullRequestChip {...env} />,
        <CommitLink label={env.gitRef.hash} url={env.gitRef.externalLink} />,
      ]}
      title={
        env.gitRef.type === "mergerequest" ? (
          <MergeRequestTitle {...env} />
        ) : (
          env.gitRef.name
        )
      }
      actions={
        <SplitButton
          options={{
            Build: { action: handleCreateAndBuild, icon: <Hardware /> },
            "Create only": { action: handleCreateOnly, icon: <Add /> },
            Configure: {
              action: () => {
                env.configureCreate();
              },
              icon: <Tune />,
            },
          }}
          status={createLoading ? "creating" : undefined}
        />
      }
    />
  );
};

const ActiveEnvTeaser = (
  env: envTypes.Environment & { refreshRepo: () => void; cloneEnv: () => void }
) => {
  const { mutate: destroy, isLoading: destroyLoading } =
    environmentsService.useRemove(env.id);

  const { mutate: build, isLoading: buildLoading } =
    environmentsService.useBuild(env.id);

  const { mutate: stopBuild, isLoading: stopBuildLoading } =
    environmentsService.useStopBuild(env.id);

  const { mutate: rebuild, isLoading: rebuildLoading } =
    environmentsService.useRebuild(env.id);

  const handleDestroy = () =>
    destroy({} as unknown as void, {
      onSuccess: () => {
        env.refreshRepo();
      },
    });

  const handleRebuild = () =>
    rebuild({} as unknown as void, {
      onSuccess: () => {
        setTimeout(() => env.refreshRepo(), 500);
      },
    });

  const handleBuild = () =>
    build({} as unknown as void, {
      onSuccess: () => {
        env.refreshRepo();
      },
    });

  const handleCopyLink = () => {
    navigator.clipboard.writeText(env.weburl ?? "No link available");
  };

  const handleLaunch = () => {
    window.open(env.weburl, "_blank");
  };

  const statusColorClass = useMemo(() => {
    switch (env.status) {
      case "building":
        return "text-status-warning";
      case "failed":
        return "text-status-error";
      case "built":
        return "text-status-success";
      case "created":
        return "text-neutral";
      case "cancelled":
        return "text-neutral";
      default:
        return "";
    }
  }, [env.status]);

  const { groupId, repoId } = useParams();

  return (
    <Teaser
      fullLink={`/groups/${groupId}/repos/${repoId}/envs/${env.id}`}
      icon={<GitRefIcon {...env} size="large" />}
      title={
        env.gitRef.type === "mergerequest"
          ? MergeRequestTitle(env)
          : (env.name ?? env.gitRef.name)
      }
      links={[
        <PullRequestChip {...env} />,
        <CommitLink label={env.gitRef.hash} url={env.gitRef.externalLink} />,
      ]}
      status={env.status}
      statusColorClass={statusColorClass}
      runningIcon={<EnvRunningStatusIcon {...env} />}
      statusIcon={[<EnvStatusIcon {...env} />]}
      info={<EnvInfo {...env} mode="teaser" />}
      actions={
        <>
          <SplitButton
            size="small"
            options={{
              ...(env.status === "created"
                ? { Build: { action: handleBuild, icon: <Hardware /> } }
                : {}),
              ...(env.status === "failed" || env.status === "cancelled"
                ? { ReBuild: { action: handleRebuild, icon: <Hardware /> } }
                : {}),
              Launch: { action: handleLaunch, icon: <Launch /> },
              ...Object.keys(env.aliases ?? {}).reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: {
                    action: () => {
                      window.open(
                        env.aliases ? env.aliases[key] : "",
                        "_blank"
                      );
                    },
                    icon: <Launch />,
                  },
                }),
                {}
              ),
              "Copy link": { action: handleCopyLink, icon: <ContentPaste /> },
              Clone: { action: env.cloneEnv, icon: <ControlPointDuplicate /> },
              ...(env.status === "built"
                ? { ReBuild: { action: handleRebuild, icon: <Hardware /> } }
                : {}),
              Remove: { action: handleDestroy, icon: <Delete /> },
            }}
            status={
              stopBuildLoading
                ? "Stopping..."
                : env.status === "building" || buildLoading
                  ? "Building..."
                  : rebuildLoading
                    ? "Rebuilding..."
                    : destroyLoading
                      ? "Removing..."
                      : env.status === "creating"
                        ? "Creating..."
                        : undefined
            }
          />
          {!stopBuildLoading && (buildLoading || env.status === "building") && (
            <IconButton
              onClick={() =>
                stopBuild({} as unknown as void, {
                  onSuccess: () => {
                    env.refreshRepo();
                  },
                })
              }
            >
              <Stop className="!fill-status-error" />
            </IconButton>
          )}
        </>
      }
    />
  );
};

const ReusableEnvTeaser = (
  reusableEnv: envTypes.ReusableEnvironment & {
    refreshRepo: () => any;
  }
) => {
  const { mutate: removeReusable, isLoading: removeLoading } =
    repositoriesService.useRemoveReusable(reusableEnv.rid, reusableEnv.label);

  const handleRemoveReusable = () =>
    removeReusable({} as unknown as void, {
      onSuccess: () => {
        reusableEnv.refreshRepo();
      },
    });

  return (
    <Teaser
      icon={<Adjust className="!fill-brand" />}
      title={reusableEnv.label}
      type={reusableEnv.from.name}
      links={[
        <CommitLink
          label={reusableEnv.from.hash}
          url={reusableEnv.from.externalLink}
        />,
      ]}
      info={
        <>
          <p className={"font-bold"}>{prettyBytes(reusableEnv.size || 0)}</p>
          <p>
            {"Saved " +
              getTimeDeltaString(new Date(reusableEnv.savedAt)) +
              " ago"}
          </p>
        </>
      }
      actions={
        <SplitButton
          options={{
            Remove: { action: handleRemoveReusable, icon: <Delete /> },
          }}
          status={removeLoading ? "Removing..." : undefined}
        />
      }
    />
  );
};

export { AvailableEnvTeaser, ActiveEnvTeaser, ReusableEnvTeaser };
