import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { To, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import { Button, FormHelperText, TextField } from "@mui/material";

import { groupsService } from "services";
import { FormTitle } from "components/atoms";

const NewMemberForm = (props: {
  groupId: string;
  redirectUrl: To | number;
}) => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<{ email: string }>();

  const {
    mutate: addGroupMember,
    isLoading: loadingGroup,
    isError: isGroupError,
    error: groupError,
  } = groupsService.useAddMember(props.groupId);

  const handleFormSubmit: SubmitHandler<{ email: string }> = (data) => {
    addGroupMember(data.email, {
      onSuccess: () => {
        typeof props.redirectUrl === "number"
          ? navigate(props.redirectUrl)
          : navigate(props.redirectUrl);
      },
    });
  };

  return (
    <form
      className="flex flex-col gap-s p-m min-w-[350px]"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <FormTitle>New member</FormTitle>

      <Controller
        name="email"
        control={control}
        render={({ field }) => <TextField label="Email" {...field} />}
      />

      <Button className="font-bold" variant="contained" type="submit">
        Invite member
      </Button>

      {loadingGroup && <p>Loading...</p>}
      {isGroupError && (
        <FormHelperText error>
          {(groupError as AxiosError<any>).response?.data?.error}
        </FormHelperText>
      )}
    </form>
  );
};

export default NewMemberForm;
