import { useMemo } from "react";
import { Article, CompassCalibration, Terminal } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { envTypes } from "types";
import prettyBytes from "pretty-bytes";

import { ServiceStatusIcon } from "components/atoms";
import Teaser from "./baseTeaser";

const ServiceTeaser = (service: envTypes.Service) => {
  const statusClasses = useMemo(
    () =>
      service.Status?.Running
        ? "!fill-status-success !text-status-success"
        : "!fill-status-error !text-status-error",
    [service.Status?.Running]
  );

  return (
    <Teaser
      icon={
        <CompassCalibration
          className={statusClasses}
        />
      }
      title={service.name}
      type={service.image}
      statusIcon={<ServiceStatusIcon {...service} />}
      status={service.Status?.Running ? "Running" : "Stopped"}
      statusColorClass={statusClasses}
      info={
        <>
          {!!service.Status?.Size && (
            <p>{prettyBytes(service.Status?.Size || 0)}</p>
          )}
        </>
      }
      actions={
        <>
          <Link to={"/service/" + service.id + "/logs"} target="_blank">
            <Button>
              <Article />
              &nbsp; <span className="hidden sm:block">Logs</span>
            </Button>
          </Link>
          <Link to={"/service/" + service.id + "/console"} target="_blank">
            <Button>
              <Terminal />
              &nbsp; <span className="hidden sm:block">Console</span>
            </Button>
          </Link>
        </>
      }
    />
  );
};

export { ServiceTeaser };
