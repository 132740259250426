import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { To, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import { Button, FormHelperText, MenuItem, TextField } from "@mui/material";

import { repoTypes } from "types";
import { groupsService, repositoriesService } from "services";
import { FormTitle } from "components/atoms";

const NewVarForm = (props: {
  parent: "group" | "repo";
  parentId: string;
  redirectUrl: To | number;
}) => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<repoTypes.NewVarProps>();

  const {
    mutate: createRepoVar,
    isLoading: loadingRepo,
    isError: isRepoError,
    error: repoError,
  } = repositoriesService.useCreateVariable(props.parentId);
  const {
    mutate: createGroupVar,
    isLoading: loadingGroup,
    isError: isGroupError,
    error: groupError,
  } = groupsService.useCreateGroupVariable(props.parentId);

  const handleFormSubmit: SubmitHandler<repoTypes.NewVarProps> = (data) => {
    switch (props.parent) {
      case "group":
        createGroupVar(data, {
          onSuccess: () => {
            typeof props.redirectUrl === "number"
              ? navigate(props.redirectUrl)
              : navigate(props.redirectUrl);
          },
        });
        break;
      case "repo":
        createRepoVar(data, {
          onSuccess: () => {
            typeof props.redirectUrl === "number"
              ? navigate(props.redirectUrl)
              : navigate(props.redirectUrl);
          },
        });
        break;
    }
  };

  return (
    <form
      className="flex flex-col gap-s p-m min-w-[350px]"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <FormTitle>New variable</FormTitle>

      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            label="Name"
            {...field}
            onChange={(e) => field.onChange(e.target.value.replace(" ", "-"))}
          />
        )}
      />
      <Controller
        name="value"
        control={control}
        render={({ field }) => <TextField label="Value" {...field} />}
      />
      <Controller
        name="visibility"
        control={control}
        render={({ field }) => (
          <>
            <TextField select label="Visibility" {...field}>
              {["always", "buildtime"].map((type) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      />

      <Button className="font-bold" variant="contained" type="submit">
        Create
      </Button>

      {(loadingRepo || loadingGroup) && <p>Loading...</p>}
      {(isRepoError || isGroupError) && (
        <FormHelperText error>
          {(repoError as AxiosError<any>)?.response?.data?.error}
          {(groupError as AxiosError<any>)?.response?.data?.error}
        </FormHelperText>
      )}
    </form>
  );
};

export default NewVarForm;
