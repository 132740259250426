import { Alert, Snackbar } from "@mui/material";
import { useContext, useState } from "react";
import { NotificationsState } from "store";

const NotificationSnacks = () => {
  const { notifications } = useContext(NotificationsState);
  const [hidden, setHidden] = useState<string[]>([]);
  return (
    <div className="container">
      {notifications?.map(
        (notification, index) =>
          notification && (
            <Snackbar
              className="mt-11 max-w-sm"
              key={"snack-" + notification.id}
              open={!hidden.includes(notification.id)}
              autoHideDuration={5000}
              onClose={() => setHidden([...hidden, notification.id])}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert severity={notification.type}>{notification.message}</Alert>
            </Snackbar>
          )
      )}
    </div>
  );
};

export default NotificationSnacks;
