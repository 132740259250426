import { ComponentProps } from "react";
import { Button } from "@mui/material";

const ButtonSecondary = (props: ComponentProps<typeof Button>) => {
  return (
    <Button sx={{ fontSize: ".8rem" }} variant={"outlined"} {...props}></Button>
  );
};

export default ButtonSecondary;
