import { FaCodePullRequest } from "react-icons/fa6";
import { envTypes } from "types";

const PullRequestChip = (env: envTypes.Environment) => {
  if (env.gitRef.type !== "pullrequest" && env.gitRef.type !== "mergerequest")
    return null;
  if (!env.gitRef.details?.sourceBranch || !env.gitRef.details?.targetBranch)
    return null;

  return (
    <a
      onClick={(e) => e.stopPropagation()}
      href={env.gitRef.details?.externalLink ?? ""}
      target={"_blank"}
      rel="noreferrer"
    >
      <p className="bg-neutral/10 text-neutral/70 text-s px-xs rounded hover:underline flex gap-xs items-center">
        {env.gitRef.details?.targetBranch}
        <FaCodePullRequest />
        {env.gitRef.details?.sourceBranch}
      </p>
    </a>
  );
};

export default PullRequestChip;
