import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

import { axiosInstance } from "./config";
import { CreateEnvProps } from "types/environmentTypes";
import { envTypes } from "types";

const requests = {
  buildEnv: (envId?: string) =>
    axiosInstance
      .post("/environment/" + (envId || "missing") + "/build")
      .then((response) => response.data),
  stopBuildEnv: (envId?: string) =>
    axiosInstance
      .post("/environment/" + (envId || "missing") + "/build/stop")
      .then((response) => response.data),
  rebuildEnv: (envId?: string) =>
    axiosInstance
      .post("/environment/" + (envId || "missing") + "/rebuild")
      .then((response) => response.data),
  createEnv: (envId?: string, options?: CreateEnvProps) =>
    axiosInstance
      .put("/environment/" + (envId || "missing"), options ?? null)
      .then((response) => response.data),
  removeEnv: (envId?: string) =>
    axiosInstance
      .delete("/environment/" + (envId || "missing"))
      .then((response) => response.data),
  startEnv: (envId?: string) =>
    axiosInstance
      .post("/environment/" + (envId || "missing") + "/start")
      .then((response) => response.data),
  stopEnv: (envId?: string) =>
    axiosInstance
      .post("/environment/" + (envId || "missing") + "/stop")
      .then((response) => response.data),

  getEnv: (envId?: string) =>
    axiosInstance
      .get("/environment/" + (envId || "missing"))
      .then((response) => response.data),
  getEnvsByIdList: (envIds: string[]) =>
    axiosInstance
      .post("/environment/list", { envIds })
      .then((response) => response.data.envs),
  getEnvName: (envId?: string) =>
    envId
      ? axiosInstance
        .get("/environment/" + (envId || "missing"))
        .then((response) => response.data.gitRef.name)
      : undefined,
  getServiceName: (serviceId?: string) =>
    serviceId
      ? axiosInstance
        .get("/service/" + (serviceId || "missing"))
        .then((response) => response.data.name)
      : undefined,
  execQuickAction: (envId: string, action: string) =>
    axiosInstance
      .get("/environment/" + envId + "/quickaction/" + action)
      .then((response) => response.data),
};

const environmentsService = {
  useBuild: (envId?: string) =>
    useMutation<any, AxiosError>("buildEnv" + (envId || "missing"), () =>
      requests.buildEnv(envId)
    ),
  useStopBuild: (envId?: string) =>
    useMutation<any, AxiosError>("stopBuildEnv" + (envId || "missing"), () =>
      requests.stopBuildEnv(envId)
    ),
  useRebuild: (envId?: string) =>
    useMutation<any, AxiosError>("rebuildEnv" + (envId || "missing"), () =>
      requests.rebuildEnv(envId)
    ),
  useCreate: (envId?: string) =>
    useMutation<any, AxiosError, CreateEnvProps>(
      "createEnv" + (envId || "missing"),
      (options?: CreateEnvProps) => requests.createEnv(envId, options)
    ),
  useRemove: (envId?: string) =>
    useMutation<any, AxiosError>("removeEnv" + (envId || "missing"), () =>
      requests.removeEnv(envId)
    ),
  useStart: (envId?: string) =>
    useMutation<any, AxiosError>("startEnv" + (envId || "missing"), () =>
      requests.startEnv(envId)
    ),
  useStop: (envId?: string) =>
    useMutation<any, AxiosError>("stopEnv" + (envId || "missing"), () =>
      requests.stopEnv(envId)
    ),

  useEnv: (envId?: string) =>
    useQuery<envTypes.Environment, AxiosError>(
      "getEnv" + (envId || "missing"),
      () => requests.getEnv(envId)
    ),
  useListOfEnvs: (envIds: string[]) =>
    useQuery<envTypes.Environment[], AxiosError>(
      "getEnvsByIdList" + envIds.join(""),
      () => requests.getEnvsByIdList(envIds),
      {
        refetchInterval: 5000,
      }
    ),
  useEnvName: (envId?: string) =>
    useQuery<any, AxiosError>("getEnvName" + (envId || "missing"), () =>
      requests.getEnvName(envId)
    ),
  useServiceName: (serviceId?: string) =>
    useQuery<any, AxiosError>("getServiceName" + (serviceId || "missing"), () =>
      requests.getServiceName(serviceId)
    ),
  useExecQuickAction: (envId: string, action: string) =>
    useMutation<any, AxiosError>(
      "execQuickAction" + envId + action,
      () => requests.execQuickAction(envId, action)
    ),
};

export default environmentsService;
