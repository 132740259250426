import { useCallback } from "react";
import { useNavigate } from "react-router";
import {
  Add,
} from "@mui/icons-material";
import { Button, IconButton, SwipeableDrawer, Tooltip } from "@mui/material";

import { groupTypes, repoTypes } from "types";
import { groupsService } from "services";

import {
  ExtList,
  NewMemberForm,
  NewVarForm,
} from "components/organisms";
import { MemberTeaser, VariableTeaser } from "components/molecules";



const GroupSettings = (props: {
  group: groupTypes.Group;
  varModalOpen: boolean;
  memberModalOpen: boolean;
}) => {
  const { data: settings, refetch: refreshSettings } =
    groupsService.useGroupSettings(props.group.id);

  const newVarUrl = "newVar";
  const newMemberUrl = "newMember";
  const redirectUrl = -1;
  const navigate = useNavigate();

  const NewVarModal = useCallback(
    () => (
      <SwipeableDrawer
        anchor="right"
        open={props.varModalOpen ?? false}
        onOpen={() => navigate(newVarUrl)}
        onClose={() => navigate(redirectUrl)}
      >
        <NewVarForm
          parent="group"
          parentId={props.group.id}
          redirectUrl={redirectUrl}
        />
      </SwipeableDrawer>
    ),
    [props.varModalOpen, props.group.id, redirectUrl, navigate]
  );
  const NewMemberModal = useCallback(
    () => (
      <SwipeableDrawer
        anchor="right"
        open={props.memberModalOpen ?? false}
        onOpen={() => navigate(newMemberUrl)}
        onClose={() => navigate(redirectUrl)}
      >
        <NewMemberForm groupId={props.group.id} redirectUrl={redirectUrl} />
      </SwipeableDrawer>
    ),
    [props.memberModalOpen, props.group.id, redirectUrl, navigate]
  );

  return (
    <>
      <ExtList
        title="Members"
        items={settings?.members}
        searchEnabled
        paginated
        pageSize={10}
        listActions={
          <Tooltip title="New member">
            <IconButton onClick={() => navigate(newMemberUrl)}>
              <Add />
            </IconButton>
          </Tooltip>
        }
        createEntry={(member: groupTypes.GroupMember) => (
          <MemberTeaser
            {...member}
            groupId={props.group.id}
            groupName={props.group.name}
            onDelete={refreshSettings}
            key={member.id}
          />
        )}
        addItemAction={
          <Button
            variant="text"
            onClick={() => navigate(newMemberUrl)}
            className="w-full flex items-center"
          >
            <Add />
            Add a new member
          </Button>
        }
        emptyMsg={"No members set!"}
      />

      <ExtList
        title="Variables"
        items={settings?.variables}
        listActions={
          <Tooltip title="New variable">
            <IconButton onClick={() => navigate(newVarUrl)}>
              <Add />
            </IconButton>
          </Tooltip>
        }
        createEntry={(variable: repoTypes.Variable) => (
          <VariableTeaser
            {...variable}
            parentType="group"
            parentId={props.group.id}
            onDelete={refreshSettings}
            key={variable.name}
          />
        )}
        addItemAction={
          <Button
            variant="text"
            onClick={() => navigate(newVarUrl)}
            className="w-full flex items-center"
          >
            <Add />
            Add a new variable
          </Button>
        }
        emptyMsg={"No variables set!"}
      />
      
      <NewVarModal />
      <NewMemberModal />
    </>
  );
};

export default GroupSettings;