import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

import { axiosInstance } from "./config";
import { userTypes } from "types";

const requests = {
  register: (user: { email: string; password: string }) =>
    axiosInstance
      .post("/user/register", user)
      .then((response) => response.data),
  logIn: (login: { email: string; password: string }) =>
    axiosInstance.post("/user/login", login).then((response) => response.data),
  logOut: () =>
    axiosInstance.post("/user/logout").then((response) => response.data),
  getUser: () =>
    axiosInstance.get("/user/info").then((response) => response.data),
};

const sessionService = {
  useRegister: () =>
    useMutation<any, AxiosError, { email: string; password: string }>(
      "register",
      requests.register
    ),
  useLogIn: () =>
    useMutation<any, AxiosError, { email: string; password: string }>(
      "login",
      requests.logIn
    ),
  useLogOut: () => useQuery<any, AxiosError>("logout", requests.logOut),

  useUser: () => useQuery<userTypes.User, AxiosError>("getUser", requests.getUser),
};

export default sessionService;
