import { envTypes } from "types";

const MergeRequestTitle = (env: envTypes.Environment) => {
  if (env.gitRef.details) {
    return (
      <>
        {env.gitRef.details.externalLink && (
          <a
            className="hover:text-brand text-brand/60 font-bold cursor-pointer"
            href={env.gitRef.details.externalLink}
            target={"_blank"}
            rel="noreferrer"
          >
            {"!" + env.gitRef.details.number + " "}
          </a>
        )}
        {env.gitRef.name}
      </>
    );
  }

  return <>{env.gitRef.name}</>;
};

export default MergeRequestTitle;
