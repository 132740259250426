import { ReactNode, useMemo, useRef, useState } from "react";
import { Cancel, ExpandLess, ExpandMore, Refresh } from "@mui/icons-material";
import { Alert, IconButton, TextField } from "@mui/material";

import { Loader, Subtitle } from "components/atoms";
import { Pager } from "components/molecules";
import ExtTabs from "./Tabs";

const ExtList = (props: {
  title?: string;
  listActions?: ReactNode;
  addItemAction?: ReactNode;
  items: any[];
  loading?: boolean;
  refresh?: () => void;
  error?: string;
  createEntry: (item: any) => JSX.Element;
  emptyMsg?: string;
  notFoundMsg?: string;
  searchEnabled?: boolean;
  tabBy?: string;
  collapsible?: boolean;
  collapsed?: boolean;
  counter?: number;
  paginated?: boolean;
  pageSize?: number;
}) => {
  const [searchKeywords, setSearchKeywords] = useState<string>("");
  const filteredItems = useMemo(
    () =>
      props.items?.filter(
        (item) =>
          JSON.stringify(item).includes(searchKeywords) || searchKeywords === ""
      ),
    [props.items, searchKeywords]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeywords(e.target.value);
  };

  // tabs will be an object grouping the items which have the same value for the tabBy property
  // tabBy can have the values like: "type", "gitRef.type" ...
  // is important to support . separated values, digging into the object
  // the tabBy value will be the label of the tab

  const tabs = useMemo(() => {
    if (!props.tabBy) return null;
    const tabBy = props.tabBy.split(".");
    return filteredItems?.reduce((acc, item) => {
      const tab = acc.find(
        (tab: any) => tab.label === item[tabBy[0]][tabBy[1]]
      );
      if (tab?.content) {
        tab.content.push(props.createEntry(item));
      } else {
        acc.push({
          label: item[tabBy[0]][tabBy[1]],
          // todo: icon: <TabIcon />,
          content: [props.createEntry(item)],
        });
      }
      return acc;
    }, []);
  }, [props, filteredItems]);

  const [collapsed, setCollapsed] = useState(props.collapsed ?? false);
  const toggleCollapsed = () => setCollapsed(!collapsed);

  const [page, setPage] = useState(1);
  const pageSize = props.pageSize ?? 10;
  const pages = Math.ceil(filteredItems?.length / pageSize);

  const pagedItems = useMemo(() => {
    if (!props.paginated) return filteredItems;
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    return filteredItems?.slice(start, end);
  }, [filteredItems, page, pageSize, props.paginated]);

  return (
    <div>
      <div className="flex items-top justify-between pb-s">
        <div className="flex items-center">
          {props.title && <Subtitle>{props.title}</Subtitle>}
          {props.refresh && (
            <IconButton onClick={props.refresh}>
              <Refresh />
            </IconButton>
          )}
          {props.listActions}
        </div>
        <div className="flex items-center gap-s">
          {props.searchEnabled && props.items?.length > 1 && (
            <div className="flex items-center">
              <TextField
                size="small"
                onChange={handleSearch}
                label="Search"
                inputRef={inputRef}
                className="bg-white"
              />
              {searchKeywords && (
                <IconButton
                  type="button"
                  onClick={() => {
                    inputRef.current?.value && (inputRef.current.value = "");
                    setSearchKeywords("");
                  }}
                >
                  <Cancel />
                </IconButton>
              )}
            </div>
          )}

          {props.counter && (
            <p className="text-neutral/60 ml-s text-m">{props.counter}</p>
          )}
          {props.collapsible && (
            <IconButton onClick={toggleCollapsed} className="ml-s" size="small">
              {collapsed ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          )}
        </div>
      </div>

      {props.loading && <Loader />}
      {props.error && !props.loading && (
        <Alert severity="error">{props.error}</Alert>
      )}
      {props.items?.length === 0 && !props.error && (
        <p className={"text-lg text-black/50"}>
          {props.emptyMsg || "No entries found"}
        </p>
      )}

      {!props.error && !props.loading && !props.tabBy && !collapsed && (
        <ul>
          {pagedItems?.map((item) => (
            <li className={"m-0 mb-xs"} key={item.id}>
              {props.createEntry(item)}
            </li>
          ))}
        </ul>
      )}

      {!props.error && !props.loading && props.tabBy && (
        <ExtTabs tabs={tabs} paginated={props.paginated} pageSize={pageSize} />
      )}

      {props.items?.length > 0 && !filteredItems?.length && (
        <p className={"text-lg text-black/50 mt-s"}>
          {props.notFoundMsg ?? "No entries found"}
        </p>
      )}

      {props.addItemAction}
      {props.paginated && pages > 1 && !props.tabBy && (
        <Pager pageSize={pageSize} pages={pages} onPageChange={setPage} />
      )}
    </div>
  );
};

export default ExtList;
