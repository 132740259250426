import { useContext } from "react";
import {
  Delete,
  DisplaySettings,
  Token,
  Visibility,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { userService } from "services";
import { userTypes } from "types";

import Teaser from "./baseTeaser";
import { NotificationsState, UserState } from "store";
import { getTimeDeltaString } from "utils";

const ApiTokenTeaser = (
  apiToken: userTypes.ApiToken & {
    onDelete: () => any;
  }
) => {
  const { mutate: deleteApiToken } = userService.useDeleteUserApiToken();

  const { addNotification } = useContext(NotificationsState);
  const { refetch } = useContext(UserState);

  const handleRemove = () => {
    deleteApiToken(apiToken.name, {
      onSuccess: () => {
        addNotification({
          type: "success",
          message: "Token removed",
        });
        refetch();
        apiToken.onDelete();
      },
    });
  };

  return (
    <Teaser
      icon={<Token className="!fill-brand" />}
      title={apiToken.name}
      type={apiToken.readOnly ? "read-only" : "read&write"}
      typeColorClass={apiToken.readOnly ? "text-neutral" : "text-neutral/70"}
      info={
        <>
          {apiToken.expiresAt && (
            <p className="text-neutral/70 pt-px leading-2">
              Expires in {getTimeDeltaString(new Date(apiToken.expiresAt))}
            </p>
          )}
        </>
      }
      actions={
        <IconButton onClick={handleRemove}>
          <Delete />
        </IconButton>
      }
    />
  );
};

export { ApiTokenTeaser };
