import { Divider } from "@mui/material";
import { AxiosError } from "axios";
import { ButtonSecondary } from "components/atoms";
import { useEffect, useState } from "react";
import { FaGoogle } from "react-icons/fa6";
import { axiosInstance } from "services/config";

const SocialAuth = () => {
  const [isGoogleEnabled, setIsGoogleEnabled] = useState(false);
  useEffect(() => {
    axiosInstance.get("/auth/google").catch((error: AxiosError) => {
      // if redirected responseURL
      setIsGoogleEnabled(
        !error.request.responseURL.includes("api/auth/google")
      );
    });
  }, []);

  if (!isGoogleEnabled) {
    return null;
  }

  return (
    <>
      <Divider />
      <ButtonSecondary
        className="font-bold"
        startIcon={<FaGoogle size={18} />}
        onClick={() => (window.location.href = `api/auth/google`)}
      >
        Google
      </ButtonSecondary>
    </>
  );
};

export default SocialAuth;
