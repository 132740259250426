import { groupsService } from "services";

import { GroupTeaser } from "components/molecules";
import { ExtList } from "components/organisms";

import NormalPage from "./layout/page";
import { AxiosError } from "axios";

export default function Groups() {
  const {
    data: groups,
    isLoading,
    refetch: refresh,
    error,
  } = groupsService.useGroups();

  return (
    <NormalPage>
      <ExtList
        items={groups ?? []}
        title="Your groups"
        loading={isLoading}
        refresh={refresh}
        error={(error as AxiosError<any>)?.response?.data?.error}
        createEntry={(group) => <GroupTeaser {...group} key={group.id} />}
      />
    </NormalPage>
  );
}
