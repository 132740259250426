import { AxiosError } from "axios";
import { useMutation } from "react-query";

import { axiosInstance } from "./config";
import { userTypes } from "types";

const requests = {
  updateUser: (user: Partial<userTypes.User>) =>
    axiosInstance.put("/user", user).then((response) => response.data),
  activateUser: (userId: string) =>
    axiosInstance
      .post(`/user/activate/${userId}`)
      .then((response) => response.data),

  createUserApiToken: (apiToken: userTypes.ApiToken) =>
    axiosInstance
      .post("/user/tokens", apiToken)
      .then((response) => response.data),
  deleteUserApiToken: (id: string) =>
    axiosInstance
      .delete(`/user/tokens/${id}`)
      .then((response) => response.data),
};

const userService = {
  useUpdateUser: () =>
    useMutation<any, AxiosError, Partial<userTypes.User>>(
      "updateUser",
      requests.updateUser
    ),
  useActivateUser: () =>
    useMutation<any, AxiosError, string>("activateUser", requests.activateUser),
    
  useCreateUserApiToken: () =>
    useMutation<any, AxiosError, userTypes.ApiToken>(
      "createUserApiToken",
      requests.createUserApiToken
    ),
  useDeleteUserApiToken: () =>
    useMutation<any, AxiosError, string>(
      "deleteUserApiToken",
      requests.deleteUserApiToken
    ),
};

export default userService;
