import { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";

import { userService } from "services";
import { Loader, Title } from "components/atoms";
import NormalPage from "./layout/page";
import Cookies from "js-cookie";

export default function Activate() {
  let params = useParams();
  const navigate = useNavigate();

  const {
    mutate: activate,
    isLoading,
    isSuccess,
    isError,
  } = userService.useActivateUser();

  useEffect(() => {
    Cookies.remove("token");

    if (params.id) {
      activate(params.id, {
        onSuccess: () => {
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        },
      });
    }
  }, [params.id, activate, navigate]);

  return (
    <NormalPage>
      <div className="flex items-center flex-col my-xl gap-s min-w-[350px]">
        <div className="bg-white rounded-sm p-l">
          <Title>Activate Account</Title>
          <br />

          {isSuccess && (
            <Alert severity="success">
              Account activated successfully, redirecting...
            </Alert>
          )}
          {isError && (
            <Alert severity="error">
              An error occurred while activating your account
            </Alert>
          )}
          {!isSuccess && !isError && (
            <Alert severity="info">
              Activating your account, please wait...
            </Alert>
          )}
        </div>
        <div className="h-m mt-s flex gap-s">
          <Loader />
          {isLoading && <p>Verifying and confirming your account...</p>}
          {isSuccess && <p>Activated! Redirecting...</p>}
        </div>
      </div>
    </NormalPage>
  );
}
