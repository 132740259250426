import { useMemo } from "react";
import prettyBytes from "pretty-bytes";

import { envTypes, repoTypes } from "types";
import { DurationString, getTimeDeltaString } from "utils";

const RepoInfo = (
  repo: repoTypes.Repository & { mode: "teaser" | "details" }
) => {
  const textClasses = useMemo(() => {
    const baseClasses = "text-neutral";
    switch (repo.mode) {
      case "teaser":
        return `${baseClasses} text-sm`;
      case "details":
        return `${baseClasses} text-m`;
    }
  }, [repo.mode]);

  const hasActiveEnvs =
    repo.envCount.building > 0 ||
    repo.envCount.failed > 0 ||
    repo.envCount.built > 0;

  const infoItems = [];

  if (!!repo.size) infoItems.push(`occupies ${prettyBytes(repo.size)}`);

  const builtText = repo.envCount.built ? `${repo.envCount.built} built` : "";
  const buildingText = repo.envCount.building
    ? `${repo.envCount.building} building`
    : "";
  const failedText = repo.envCount.failed
    ? `${repo.envCount.failed} failed`
    : "";
  if (hasActiveEnvs) {
    infoItems.push(
      `has ${[builtText, buildingText, failedText]
        .filter(Boolean)
        .join(" / ")} envs`
    );
  }

  return (
    <>
      {infoItems.map((item, index) => (
        <p key={index} className={textClasses}>
          {item}
          {index < infoItems.length - 1 && ","}
        </p>
      ))}
    </>
  );
};

export default RepoInfo;
