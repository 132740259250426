import { useContext } from "react";
import { Link } from "react-router-dom";
import { Group, Settings } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { groupTypes } from "types";
import { UserState } from "store";

import Teaser from "./baseTeaser";
import GroupInfo from "../info/groupInfo";

const GroupTeaser = (group: groupTypes.Group) => {
  const { userInfo } = useContext(UserState);

  return (
    <Teaser
      fullLink={"/groups/" + group.id + "/repos"}
      icon={<Group className="!fill-brand" />}
      title={group.name}
      info={<GroupInfo {...group} mode="teaser" />}
      type={group.name === userInfo?.email ? "personal" : "invited"}
      actions={
        <>
          <Link to={"/groups/" + group.id + "/settings"}>
            <IconButton>
              <Settings />
            </IconButton>
          </Link>
        </>
      }
    />
  );
};

export { GroupTeaser };
