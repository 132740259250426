import { Person, Delete } from "@mui/icons-material";
import { groupsService } from "services";
import { groupTypes } from "types";
import Teaser from "./baseTeaser";
import { IconButton } from "@mui/material";

const MemberTeaser = (
  member: groupTypes.GroupMember & {
    groupId: string;
    groupName: string;
    onDelete: () => any;
  }
) => {
  const { mutate: deleteMember } = groupsService.useRemoveMember(
    member.groupId
  );

  const isOwner = member.email === member.groupName;

  const handleRemove = () => {
    deleteMember(member.email, {
      onSuccess: () => member.onDelete(),
    });
  };

  return (
    <Teaser
      icon={<Person className="!fill-brand" />}
      title={member.email}
      type={isOwner ? "Owner" : ""}
      actions={
        !isOwner ? (
          <IconButton onClick={handleRemove}>
            <Delete />
          </IconButton>
        ) : (
          <></>
        )
      }
    />
  );
};

export { MemberTeaser };
