import { repoTypes } from "types";
import { FaBitbucket, FaGithub, FaGitlab } from "react-icons/fa6";
import { MdInventory } from "react-icons/md";
import { SiMicrosoftazure } from "react-icons/si";

const RepositoryIcon = (props: {
  type?: repoTypes.RepoType;
  size?: "xl" | "large" | "medium" | "small";
}) => {
  const numSize =
    props.size === "xl"
      ? "1.7em"
      : props.size === "large"
      ? "1.5em"
      : props.size === "medium"
      ? "1.45em"
      : "1.2em";

  switch (props.type) {
    case "gitlab":
      return <FaGitlab className="!fill-brand" size={numSize} />;
    case "github":
      return <FaGithub className="!fill-brand" fontSize={numSize} />;
    case "azure":
      return <SiMicrosoftazure className="!fill-brand" size={numSize} />;
    case "bitbucket":
      return <FaBitbucket className="!fill-brand" size={numSize} />;
    default:
      return <MdInventory className="!fill-brand" size={numSize} />;
  }
};

export default RepositoryIcon;
