import { Alert, AlertTitle } from "@mui/material";
import { ReactNode, useMemo } from "react";

const SettingOption = (props: {
  type: "normal" | "danger";
  title: string;
  description: string | ReactNode;
  actions: ReactNode;
  fullWidthActions?: boolean;
}) => {
  const severity = useMemo(
    () => (props.type === "normal" ? "success" : "error"),
    [props.type]
  );

  const containerClass = useMemo(
    () => (props.fullWidthActions ? "" : "sm:flex-nowrap"),
    [props.fullWidthActions]
  );

  const actionsClass = useMemo(
    () => (props.fullWidthActions ? "!w-full" : "min-w-[210] lg:min-w-[300px]"),
    [props.fullWidthActions]
  );

  const textClass = useMemo(
    () => (props.type === "normal" ? "text-brand" : "text-status-error"),
    [props.type]
  );

  return (
    <Alert
      severity={severity}
      variant="outlined"
      className="mb-s !w-full !bg-white group relative"
      icon={false}
    >
      <div
        className={
          "flex gap-x-xl gap-y-m flex-wrap !w-full justify-between " +
          containerClass
        }
      >
        <div className="w-3/5">
          <AlertTitle className={"!font-bold " + textClass}>
            {props.title}
          </AlertTitle>
          {props.description}
        </div>
        <div className={actionsClass}>{props.actions}</div>
      </div>
    </Alert>
  );
};

export default SettingOption;
