import { Delete, DisplaySettings } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { groupsService, repositoriesService } from "services";
import { repoTypes } from "types";
import Teaser from "./baseTeaser";

const VariableTeaser = (
  variable: repoTypes.Variable & {
    parentType: "repo" | "group";
    parentId: string;
    onDelete: () => any;
  }
) => {
  const { mutate: deleteRepoVariable } = repositoriesService.useRemoveVariable(
    variable.parentId
  );
  const { mutate: deleteGroupVariable } = groupsService.useRemoveVariable(
    variable.parentId
  );

  const handleRemove = () => {
    switch (variable.parentType) {
      case "repo":
        deleteRepoVariable(variable.name, {
          onSuccess: () => variable.onDelete(),
        });
        break;
      case "group":
        deleteGroupVariable(variable.name, {
          onSuccess: () => variable.onDelete(),
        });
        break;
    }
  };

  return (
    <Teaser
      icon={<DisplaySettings className="!fill-brand" />}
      title={variable.name}
      info={
        <>
          <p className="text-neutral/70 pt-px leading-2">
            Visibility: <b>{variable.visibility}</b>
          </p>
          <p className="text-neutral/70 pt-px leading-2 text-ellipsis max-w-[40%] lg:max-w-[50%] overflow-hidden whitespace-nowrap">
            Value: <b>{variable.value}</b>
          </p>
        </>
      }
      actions={
        <IconButton onClick={handleRemove}>
          <Delete />
        </IconButton>
      }
    />
  );
};

export { VariableTeaser };
