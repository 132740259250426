import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Adjust, Group } from "@mui/icons-material";

import { groupsService, repositoriesService } from "services";

import { ImPlus } from "react-icons/im";
import { GroupOption, RepoOption, EnvOption } from "components/molecules";
import { RepositoryIcon } from "components/atoms";

const NavigationSelectors = () => {
  const navigate = useNavigate();
  const { groupId, repoId } = useParams();

  const { data: groups, isLoading: loadingGroups } = groupsService.useGroups();

  const { data: group, isLoading: loadingGroup } =
    groupsService.useGroup(groupId);

  const { data: repo } = repositoriesService.useRepo(repoId);

  const handleNewRepo = () => {
    navigate(`/groups/${groupId}/new-repo`);
  };

  const [envSearch, setEnvSearch] = useState("");
  const [repoSearch, setRepoSearch] = useState("");

  return (
    <div className="flex flex-col gap-m grow">
      <FormControl>
        <InputLabel id="group-select-label">Group</InputLabel>
        <Select
          labelId="group-select-label"
          className="w-full"
          size="small"
          label="Group"
          value={group?.name ?? "Select group"}
          defaultValue=""
          disabled={loadingGroups}
          renderValue={(value) => (
            <div className="flex items-center gap-s overflow-clip">
              <Group className="!fill-brand" fontSize="small" />
              {value}
            </div>
          )}
        >
          {groups?.length === 0 && <MenuItem>No groups</MenuItem>}
          {groups
            ?.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
            ?.map((group) => <GroupOption key={group.id} {...group} />)}
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel id="repo-select-label">Repo</InputLabel>
        <Select
          labelId="repo-select-label"
          className={
            "!min-w-[10rem] overflow-clip" +
            (loadingGroup || !groupId ? "opacity-40" : "")
          }
          size="small"
          label="Repo"
          value={repo?.name ?? "Select repo"}
          defaultValue=""
          renderValue={(value) => (
            <div className="flex items-center gap-s overflow-clip">
              <RepositoryIcon type={repo?.type} size="small" />
              {value}
            </div>
          )}
          disabled={loadingGroup || !groupId}
        >
          {(group?.repositories?.length ?? 0) > 10 && (
            <div className="flex items-center p-s">
              <TextField
                size="small"
                label="Search"
                className="w-full mx-m"
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => setRepoSearch(e.target.value)}
              />
            </div>
          )}
          {group?.repositories?.length === 0 && (
            <MenuItem>No repositories</MenuItem>
          )}
          {group?.repositories
            ?.filter(
              (repo) =>
                repo.name?.toLowerCase().includes(repoSearch.toLowerCase()) ||
                repo.type.includes(repoSearch.toLowerCase()),
            )
            ?.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
            ?.map((repo) => (
              <RepoOption key={repo.id} {...repo} groupId={groupId} />
            ))}
          <Divider />
          <MenuItem
            value="new"
            className="flex items-center gap-s !font-normal"
            onClick={handleNewRepo}
          >
            <ImPlus />
            new repo
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl className="max-h-[80vh]">
        <InputLabel shrink className="!bg-white !-ml-xs !pl-xs !pr-s !rounded-full">
          Env
        </InputLabel>

        <div className="!border !border-neutral/50 !rounded py-s max-h-[60vh] overflow-scroll">
          {repo?.environments?.length === 0 && (
            <MenuItem disabled>No environments</MenuItem>
          )}
          {!repo && (
            <div className="px-[12px] flex items-center gap-s overflow-clip">
              <Adjust className="!fill-brand" fontSize="small" />
              <p className="text-neutral/70">Select env</p>
            </div>
          )}
          {(repo?.environments?.length ?? 0) > 10 && (
            <div className="flex items-center p-s">
              <TextField
                size="small"
                label="Search"
                className="w-full mx-m"
                onChange={(e) => setEnvSearch(e.target.value)}
              />
            </div>
          )}
          {repo?.environments
            // status: "empty" | "building" | "failed" | "built" | "created" | "creating" | "cancelled" | "queued"
            // sort by status, so that "created" environments are shown first, then "failed", then "building", then "empty" and else
            ?.sort((a, b) => {
              if (a.status === b.status)
                return a.gitRef?.name > b.gitRef?.name ? 1 : -1;
              if (a.status === "created") return -1;
              if (b.status === "created") return 1;
              if (a.status === "failed") return -1;
              if (b.status === "failed") return 1;
              if (a.status === "building") return -1;
              if (b.status === "building") return 1;
              if (a.status === "empty") return 1;
              if (b.status === "empty") return -1;
              return 0;
            })
            ?.filter(
              (env) =>
                env.name?.toLowerCase().includes(envSearch.toLowerCase()) ||
                env.gitRef?.name
                  ?.toLowerCase()
                  .includes(envSearch.toLowerCase()) ||
                env.status.includes(envSearch.toLowerCase()) ||
                env.id.includes(envSearch.toLowerCase()) ||
                env.gitRef?.type.includes(envSearch.toLowerCase()),
            )
            ?.map((env) => (
              <EnvOption
                key={env.id}
                {...env}
                groupId={groupId}
                repoId={repoId}
              />
            ))}
        </div>
      </FormControl>
    </div>
  );
};

export default NavigationSelectors;
