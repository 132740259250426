const getTimeDeltaString = (time: Date) => {
  return getTimeDeltaSinceString(time, new Date()) ;
};

const getTimeDeltaSinceString = (since: Date, until: Date) => {
  // calculate time left
  const timeLeft = Math.abs(
    Math.floor((until.getTime() - since.getTime()) / 60000)
  );

  // return time left in string format "x minutes" if less than an hour left, "x hours" if less than a day left, "x days" if less than a week left or "x days and y hours" if more than a day left
  if (timeLeft < 60) {
    return `${timeLeft} minute${timeLeft === 1 ? "" : "s"}`;
  }

  if (timeLeft < 1440) {
    return `${Math.round(timeLeft / 60)} hour${
      Math.round(timeLeft / 60) === 1 ? "" : "s"
    }`;
  }

  if ((timeLeft % 1440) / 60 === 0) {
    return `${Math.floor(timeLeft / 1440)} day${
      Math.floor(timeLeft / 1440) === 1 ? "" : "s"
    }`;
  }

  return `${Math.floor(timeLeft / 1440)} day${
    Math.floor(timeLeft / 1440) === 1 ? "" : "s"
  } and ${Math.round((timeLeft % 1440) / 60)} hour${
    Math.round((timeLeft % 1440) / 60) === 1 ? "" : "s"
  }`;
};

const getTimeDeltaSince = (since: Date, until: Date) => {
  const millisLeft = Math.abs(until.getTime() - since.getTime());

  // return like MM:ss:mmmmm , always pad with 0
  const minutes = Math.floor(millisLeft / 60000);
  const seconds = Math.floor((millisLeft % 60000) / 1000);
  const milliseconds = millisLeft % 1000;
  return `${String(minutes)}:${String(seconds).padStart(2, "0")}.${String(
    milliseconds
  ).padStart(3, "0")}`;
};

export { getTimeDeltaString, getTimeDeltaSince, getTimeDeltaSinceString };
